import { environment } from "src/environments/environment";

export interface MenuRoutes {

  id: string;
  title: string;
  tooltip: string;
  path: string;
  icon: string;
  absoluteUrl: boolean;
  separated?: boolean;
}

export const userMenuRoutes: MenuRoutes[] = [
  // {
  //   id: 'user1',
  //   title: 'spdf.app.navigation.usersettings',
  //   tooltip: 'spdf.app.navigation.usersettings',
  //   path: '',
  //   icon: 'fa-cog',
  //   absoluteUrl: false,
  // },
  {
    id: 'user2',
    title: 'spdf.app.navigation.logout',
    tooltip: 'spdf.app.navigation.logout',
    path: 'logout',
    icon: 'fa-sign-out',
    absoluteUrl: true,
    separated: true,
  },
];

export const gearMenuRoutes = [
  {
    id: 'userAssignments',
    title: 'spdf.app.navigation.userassignments',
    tooltip: 'spdf.app.navigation.userassignments',
    path: '/userAssignments/',
    absoluteUrl: true,
    enableFeature: true
  },
  {
    id: 'configurationReports',
    title: 'spdf.app.navigation.configurationReports',
    tooltip: 'spdf.app.navigation.configurationReports',
    path: '/configurationReports/',
    absoluteUrl: true,
    enableFeature: environment.approvalFlowFeatureFlag
  },
  {
    id: 'invoiceSettings',
    title: 'spdf.app.navigation.invoiceSettings',
    tooltip: 'spdf.app.navigation.invoiceSettings',
    path: '/invoiceSettings/',
    absoluteUrl: true,
    enableFeature: environment.multiInvoiceFeatureFlag
  }
];

export const logoRoute: MenuRoutes =
{
  id: 'home',
  title: 'spdf.app.navigation.home',
  tooltip: 'spdf.app.navigation.home',
  path: '',
  icon: '',
  absoluteUrl: true,
  separated: true,
};