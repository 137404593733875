import { Component, Inject, OnInit } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
  selector: 'icon-snack-bar',
  template: `<div class="gt-snackbar-content-div">
    <mat-icon
      color="{{ getIcon!.color }}"
      fontSet="fas"
      class="gt-icon"
      fontIcon="{{ getIcon!.icon }}"
    ></mat-icon>
    <span style="color:#FFFFFF;padding-top:8px;padding-bottom:8px;">{{
      data?.message
    }}</span>
    <button
      mat-button
      color="primary"
      *ngIf="hasAction"
      (click)="data?.action === 'Dismiss' ? snackBarRef.dismiss() : null"
    >
      {{ data?.action }}
    </button>
  </div>`,
  styleUrls: ['snackbar.scss'],
})
export class IconSnackBarComponent implements OnInit {
  get hasAction(): boolean {
    return !!this.data.action;
  }
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<IconSnackBarComponent>
  ) { }
  ngOnInit() { }

  get getIcon() {
    switch (this.data.snackType) {
      case 'Success':
        return { icon: 'fa-check-circle', color: 'gt-color-success' };
      case 'Error':
        return { icon: 'fa-exclamation-circle', color: 'gt-color-error' };

      case 'Warn':
        return { icon: 'fa-exclamation-triangle', color: 'gt-color-warning' };

      case 'Info':
        return { icon: 'fa-info-circle', color: 'gt-color-info' };
    }
  }
}
