import { Component, OnInit, Input, ContentChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { LocalizationService } from 'src/app/app-services/localization.service';

@Component({
  selector: 'info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss']
})
export class InfoBannerComponent extends BaseTranslationService implements OnInit {

  @ContentChild(TemplateRef) messageTemplate: TemplateRef<any>;
  @Input() secondaryLink?: string;
  @Input() primaryLink?: string;

  @Input() secondaryLinkRoute?: string;
  @Input() primaryLinkRoute?: string;

  hideBanner: boolean = false;

  constructor(private router: Router, localization: LocalizationService) {
    super(localization);

  }

  ngOnInit(): void {

  }
}
