import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { LocalizationService } from 'src/app/app-services/localization.service';
export interface DialogData {
  messageKey: string
}

@Component({
  selector: 'app-unsaved-data-warning',
  templateUrl: './unsaved-data-warning.component.html',
  styleUrls: ['./unsaved-data-warning.component.scss']
})
export class UnsavedDataWarningComponent extends BaseTranslationService implements OnInit {

  constructor(private dialogRef: MatDialogRef<UnsavedDataWarningComponent>, localization: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    super(localization);
  }

  ngOnInit(): void {
  }

  discard() {
    this.dialogRef.close({
      ok: true,
      action: 'discard'
    });
  }

  goBack() {
    this.dialogRef.close({
      ok: true,
      action: 'goBack'
    });
  }

}
