import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { LocalizationService } from 'src/app/app-services/localization.service';
import { ApiService } from '../app-services/api.service';
import { AppConfig } from '../app.config';

export interface DialogData {
  emailData: any;
}

@Component({
  selector: 'email-details',
  templateUrl: './email-details.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class EmailDetailsComponent extends BaseTranslationService implements OnInit {

  constructor(private dialogRef: MatDialogRef<EmailDetailsComponent>, localization: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private router: Router, public apiService: ApiService) {
    super(localization);
  }
  ngOnInit(): void {
   console.log(this.data);
  }

}
