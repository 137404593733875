import { Injectable } from '@angular/core';
import { LocalizationService } from './localization.service';
@Injectable({
  providedIn: 'root'
})
export class BaseTranslationService {

  constructor(public localization: LocalizationService
  ) { }
  //localized translation 
  tr(text: string) {
    return this.localization.translate(text);
  }
}
