<mat-expansion-panel hideToggle [expanded]="true">
  <mat-expansion-panel-header #InvoiceLines (click)="InvoiceLines._toggle()">
    <mat-panel-title style="padding-left: 8px;">
      <mat-form-field appearance="legacy" class="gt-form-field-without-label gt-quicksearch-input filterWidth"
        autocomplete="off" floatLabel="always">
        <mat-label id="gt-mat-label-1"></mat-label>
        <input matInput [(ngModel)]="searchText" (keyup)="onFilterTextChange()" autocomplete="off"
          aria-labelledby="gt-mat-label-1" placeholder="{{tr('spdf.app.module.buup.searchIcon')}}">

        <button class="gt-form-field-clear" mat-icon-button matSuffix *ngIf="searchText" (click)="clearSearchText()"
          matTooltip="{{tr('spdf.app.tooltips.clear')}}" attr.aria-label="{{tr('spdf.app.tooltips.clear')}}">
          <mat-icon> cancel </mat-icon>
        </button>

        <button mat-icon-button matSuffix disabled class="gt-search-icon"
          attr.aria-label="{{tr('spdf.app.module.buup.searchIcon')}}">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </mat-panel-title>
    <mat-panel-description style="margin-right: 0px">
      <button mat-icon-button id="addLineItemButton" color="primary" (click)="$event.stopPropagation(); addRow()"
        matTooltip="{{tr('spdf.app.tooltips.add')}}">
        <mat-icon fontSet="far" fontIcon="fa-plus">
        </mat-icon>
      </button>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <gt-editable-grid [config]="gridConfig"></gt-editable-grid>
</mat-expansion-panel>