export class ElementBase<T> {
  value: T;
  defaultValue: T;
  fieldCode: string;
  fieldType: string;
  label: string;
  required: boolean;
  uiOrder: number;
  controlType: string;
  x: number;
  y: number;
  width: number;
  height: number;
  readonly: boolean;
  page: number;
  hidden: boolean;
  minLength: number;
  maxLength: number;
  fieldTypeClassification: string;
  isVatIdField: boolean;
  isIbanField: boolean;

  constructor(options: {
    value?: T,
    defaultValue?: T,
    fieldCode?: string,
    fieldType?: string,
    label?: string,
    required?: boolean,
    uiOrder?: number,
    controlType?: string,
    x?: number,
    y?: number,
    width?: number,
    height?: number,
    readonly?: boolean,
    page?: number,
    hidden?: boolean,
    minLength?: number,
    maxLength?: number,
    fieldTypeClassification?: string,
    isVatIdField?: boolean,
    isIbanField?: boolean

  } = {}) {
    this.value = options.value as T;
    this.defaultValue = options.defaultValue as T;
    this.fieldCode = options.fieldCode || '';
    this.fieldType = options.fieldType || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.uiOrder = options.uiOrder === undefined ? 1 : options.uiOrder;
    this.controlType = options.controlType || '';
    this.x = options.x || 0;
    this.y = options.y || 0;
    this.width = options.width || 0;
    this.height = options.height || 0;
    this.readonly = !!options.readonly;
    this.page = options.page || 0;
    this.hidden = false;
    this.minLength = options.minLength || 0;
    this.maxLength = options.maxLength || 1;
    this.fieldTypeClassification = options.fieldTypeClassification || '';
    this.isVatIdField = options.isVatIdField || false;
    this.isIbanField = options.isIbanField || false;
  }
}