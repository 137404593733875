import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Event, NavigationEnd, Router } from '@angular/router';
import { ApiService } from './app-services/api.service';
import { BaseTranslationService } from './app-services/base.translation.service';
import { CommonService } from './app-services/common.service';
import { appRoutes } from './app-services/const';
import { LocalizationService } from './app-services/localization.service';
import { NavigationRoutesService } from './app-services/navigation-routes.service';
import { SessionService } from './app-services/session.service';
import { AppConfig } from './app.config';
import { ExtendSessionComponent } from './dialogs/extend-session.component';
import { clearTimeout, setTimeout } from 'worker-timers';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseTranslationService {
  title = 'onepdf-ui';
  currentApplicationVersion = environment.appVersion;
  dialogRef: any;
  timeoutHandle: any;
  constructor(
    private navigationRouterService: NavigationRoutesService,
    public commonService: CommonService, localizationService: LocalizationService,
    public dialog: MatDialog, private router: Router,
    private _sessionService: SessionService, public apiService: ApiService) {
    super(localizationService);
    _sessionService.changeEmitted$.subscribe(timeInMins => {
      if (this.timeoutHandle) {
        clearTimeout(this.timeoutHandle);
      }
      this.timeoutHandle = setTimeout(async () => await this.openExtendSessionDialog(), (timeInMins - AppConfig.SESSION_WARNING_STARTS_TIME_IN_MINS) * 60 * 1000);
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.navigationRouterService.setCurrentRoute();
      }
    })
  }

  async ngOnInit() {
    this.navigationRouterService.setUpNavigationBar(this.localizeRoutes());
    this.navigationRouterService.updateUserNavigationData();
    this.commonService.hidePanel = false;
  }


  localizeRoutes() {
    appRoutes.map(rt => {
      if (rt.title == "SmartPDF") {
        // rt.title = this.tr('basware-admin.app.navigation.home') ;
      }
    });

    return appRoutes;
  }

  async openExtendSessionDialog() {

    let isDialogOpen = this.isSessionDialogOpen();
    if (!isDialogOpen) {
      this.dialogRef = this.dialog.open(ExtendSessionComponent, {
        width: '480px',
        disableClose: true,
        data: {
          messageKey1: 'spdf.app.dialog.session.description.para1',
          messageKey2: 'spdf.app.dialog.session.description.para2'
        }
      });
    }
  }

  isSessionDialogOpen() {
    return !!this.dialogRef && this.dialogRef.componentInstance instanceof ExtendSessionComponent;
  }
}
