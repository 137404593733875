import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { LocalizationService } from 'src/app/app-services/localization.service';
import { ApiService } from '../app-services/api.service';
import { AppConfig } from '../app.config';
import { clearInterval, setInterval } from 'worker-timers';

export interface DialogData {
  messageKey1: string,
  messageKey2: string
}

@Component({
  selector: 'app-unsaved-data-warning',
  templateUrl: './extend-session.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class ExtendSessionComponent extends BaseTranslationService implements OnInit {

  constructor(private dialogRef: MatDialogRef<ExtendSessionComponent>, localization: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private router: Router, public apiService: ApiService) {
    super(localization);
  }

  sessionLeftInSeconds = AppConfig.SESSION_WARNING_STARTS_TIME_IN_MINS * 60;
  minsLeft = 0;
  secsLeft = 0;
  intervalHandle: any;
  ngOnInit(): void {
    this.intervalHandle = setInterval(async () => {
      this.sessionLeftInSeconds--;
      this.minsLeft = Math.floor(this.sessionLeftInSeconds / 60);
      this.secsLeft = this.sessionLeftInSeconds - this.minsLeft * 60;

      if (this.sessionLeftInSeconds == 0) {
        await this.logout();
      }
    }, 1000);
  }

  async logout() {
    sessionStorage.clear();
    clearInterval(this.intervalHandle);
    this.router.navigate(['logout']);
  }

  async extend() {
    try {
      await this.apiService.ExtendSession(null);
      clearInterval(this.intervalHandle);
      this.dialogRef.close();
    } catch (err) {
      //handle error
    }

  }

}
