export const DecimalFields = ["quantity", "unitPrice", "taxRate", "taxSum", "netTotal"];

export interface InvoiceData {
  invoiceNumber: string;
  invoiceDate: string;
  customerName: string;
  accountCode: string;
  amount: number;
}

export class InvoiceLineData {
  rowNumber: number;
  productCode: { value: string, data: any };
  description: { value: string, data: any };
  // date: string;
  quantity: { value: string, data: any };
  unit: { value: string, data: any };
  unitPrice: { value: string, data: any };
  taxRate: { value: string, data: any };
  taxSum: { value: string, data: any };
  netTotal: { value: string, data: any };

  constructor(rowNumber: any) {
    this.rowNumber = rowNumber;
    this.productCode = { value: '', data: {} };
    this.description = { value: '', data: {} };
    // this.date = '';
    this.quantity = { value: '', data: {} };
    this.unit = { value: '', data: {} };
    this.unitPrice = { value: '', data: {} };
    this.taxRate = { value: '', data: {} };
    this.taxSum = { value: '', data: {} };
    this.netTotal = { value: '', data: {} };
  }
}

export class TaskResponseLineItem {
  "type": string;
  "value": string;
  "owner": string;
  "page": number;
  "x": number;
  "y": number;
  "width": number;
  "height": number;
  "dpi": number;
}
