<div id="dynamic-form" class="doc-container">
    <mat-toolbar class="gt-titlebar">
        <div class="gt-titlebar-back-button">
            <button mat-icon-button color="primary" (click)="goBack()" matTooltip="{{tr('spdf.app.tooltips.goBack')}}"
                attr.aria-label="{{tr('spdf.app.tooltips.goBack')}}">
                <mat-icon fontSet="far" fontIcon="fa-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="gt-titlebar-content-wrapper">
            <div class="gt-titlebar-left-content-wrapper">
                <div class="gt-titlebar-main-title mat-typography">
                    <h1>{{taskResponse?.businessUnit}}</h1>
                </div>
                <div class="gt-titlebar-subtitle-wrapper">
                    <span class="gt-titlebar-subtitle-content mat-body-1">
                        <span class="gt-titlebar-subtitle">{{taskResponse?.receivingEmail}}</span>
                    </span>
                    <mat-divider [vertical]="true" role="separator"></mat-divider>
                    <span class="gt-titlebar-subtitle-content mat-body-1">
                        <span class="gt-titlebar-subtitle">{{taskResponse?.invoiceAddress}}</span>
                    </span>
                    <mat-divider [vertical]="true" role="separator"></mat-divider>
                    <span [ngClass]="{'gt-badge-muted': taskResponse?.messageCount==0 }"
                        class="gt-badge">{{taskResponse?.messageCount}}</span>&nbsp;
                    <span class="gt-titlebar-subtitle-content mat-body-1">
                        <span
                            class="gt-titlebar-subtitle">{{tr("spdf.app.module.bu.validation.header.docsInQueue")}}</span>
                    </span>
                </div>
            </div>
            <div class="gt-titlebar-right-content-wrapper" *ngIf="!taskResponse?.allDocsValidated">
                <button mat-stroked-button color="primary"
                    [disabled]="processingTask || skippingTask || isContentLoading" class="gt-progress-button"
                    (click)="checkDirtySkipTask(true,false)">
                    <div *ngIf="skippingTask">
                        <span>{{tr("spdf.app.actions.skip")}}</span>
                        <div>
                            <mat-progress-spinner [diameter]="spinnerDiameter" [mode]="mode">
                            </mat-progress-spinner>
                        </div>
                    </div>
                    <span *ngIf="!skippingTask">{{tr("spdf.app.actions.skip")}}</span>
                </button>
                <button mat-flat-button color="primary"
                    [disabled]="processingTask || skippingTask || isContentLoading || (!isFormValid()|| !isLineItemsValid)"
                    class="gt-progress-button" (click)="sendToProcess()">
                    <div *ngIf="processingTask">
                        <span>{{tr("spdf.app.actions.sendToProcess")}} </span>
                        <div>
                            <mat-progress-spinner [diameter]="spinnerDiameter" [mode]="mode">
                            </mat-progress-spinner>
                        </div>
                    </div>
                    <span *ngIf="!processingTask">{{tr("spdf.app.actions.sendToProcess")}}</span>
                </button>
                <!-- hidden as part of https://jira.basware.com/browse/SPDF-242-->
                <button mat-icon-button color="primary" [matMenuTriggerFor]="ellipsisDocumentMenu"
                    matTooltip="{{tr('spdf.app.tooltips.moreActions')}}" [disabled]="processingTask || skippingTask"
                    attr.aria-label="{{tr('spdf.app.tooltips.moreActions')}}">
                    <mat-icon fontSet="far" fontIcon="fa-ellipsis-v">
                    </mat-icon>
                </button>
                <mat-menu #ellipsisDocumentMenu="matMenu" yPosition="below" [overlapTrigger]="false"
                    (click)="$event.stopPropagation();">
                    <button *ngIf="false" [disabled]="processingTask || skippingTask" mat-menu-item>
                        <span>{{tr("spdf.app.actions.forwardToOtherBu")}}</span>
                    </button>
                    <button *ngIf="false" [disabled]="processingTask || skippingTask" mat-menu-item>
                        <span>{{tr("spdf.app.actions.reject")}}</span>
                    </button>
                    <button [disabled]="processingTask || skippingTask" (click)="getEmailDetails()" mat-menu-item>
                        <span>{{tr("spdf.app.actions.emailDetails")}}</span>
                    </button>
                </mat-menu>
                <div class="gt-titlebar-button-group" *ngIf="imgControlCollapsed && navigationControlExpanded">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="moreOptionsMenuResized"
                        matTooltip="{{tr('spdf.app.tooltips.moreActions')}}" [disabled]="processingTask || skippingTask"
                        attr.aria-label="{{tr('spdf.app.tooltips.moreActions')}}">
                        <mat-icon fontSet="far" fontIcon="fa-ellipsis-v">
                        </mat-icon>
                    </button>
                    <mat-menu #moreOptionsMenuResized="matMenu" yPosition="below" [overlapTrigger]="false"
                        (click)="$event.stopPropagation();">
                        <button mat-menu-item [disabled]="processingTask || skippingTask" (click)="zoomOut(false)">
                            {{tr("spdf.app.tooltips.imgControls.zoomOut")}}</button>
                        <button mat-menu-item [disabled]="processingTask || skippingTask" (click)="zoomIn()">
                            {{tr("spdf.app.tooltips.imgControls.zoomIn")}} </button>
                        <button mat-menu-item [disabled]="processingTask || skippingTask" (click)="fitToWidth()">
                            {{tr("spdf.app.module.bu.validation.image.text.fitToWidth")}}</button>
                        <button mat-menu-item [disabled]="processingTask || skippingTask" (click)="fitToHeight()">
                            {{tr("spdf.app.module.bu.validation.image.text.fitToHeight")}}</button>
                        <mat-divider></mat-divider>
                        <button mat-menu-item [disabled]="processingTask || skippingTask" (click)="rotate('left')">
                            {{tr("spdf.app.tooltips.imgControls.r2l")}}</button>
                        <button mat-menu-item [disabled]="processingTask || skippingTask" (click)="rotate('right')">
                            {{tr("spdf.app.tooltips.imgControls.r2r")}}</button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </mat-toolbar>

    <info-banner *ngIf="isAdmin && configApprovalBannerDetails && configApprovalBannerDetails.isPending"
        class="info-banner" [primaryLink]="'spdf.app.module.smartPdf.configuration.details'"
        [primaryLinkRoute]="'/configurationReports'">
        <ng-template>
            {{tr('spdf.app.module.smartpdf.configuration.notification')}}:
            <strong>{{configApprovalBannerDetails?.name}}</strong>
        </ng-template>
    </info-banner>

    <mat-toolbar #bannerE *ngIf="showErrorBanner" class=" error-banner gt-banner gt-banner-error">
        <mat-icon fontSet="fas" fontIcon="fa-exclamation-circle" class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden"> Error</span>
        <span class="gt-banner-content mat-body-1">
            <span class="gt-banner-messageblock">{{tr("spdf.app.module.bu.validation.banner.error")}}</span>
        </span>
        <button mat-icon-button attr.aria-label="{{tr('spdf.app.tooltips.close')}}" (click)="showErrorBanner = false"
            color="gt-color-info" class="gt-banner-close-btn">
            <mat-icon fontSet="far" fontIcon="fa-times" aria-hidden="false" class="gt-icon"
                matTooltip="{{tr('spdf.app.tooltips.close')}}">
            </mat-icon>
        </button>
    </mat-toolbar>
    <div class="gt-splitter-container" *ngIf="!taskResponse?.allDocsValidated">

        <as-split *ngIf="!taskResponse?.allDocsValidated" direction="vertical" [gutterSize]="8"
            (dragEnd)="dragEnd($event)">
            <as-split-area [size]="taskResponse?.isLineItemsActive ? splitAreaSizes[0] : 100 " [minSize]="20">
                <as-split direction="horizontal" [gutterSize]="8">
                    <as-split-area [size]="35" [minSize]="20" class="form-split-area" style="overflow: hidden;">
                        <mat-expansion-panel hideToggle [expanded]="true">
                            <mat-expansion-panel-header #uniquePanelName (click)="uniquePanelName._toggle()">
                                <mat-panel-title> {{tr("spdf.app.module.bu.validation.form.name")}}
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <div *ngIf="isContentLoading" class="gt-spinner-container">
                                <mat-progress-spinner [diameter]="32" mode="indeterminate">
                                </mat-progress-spinner>
                            </div>
                            <form fxLayout="row wrap" fxLayoutGap="24px grid" *ngIf="form && !isContentLoading"
                                (keydown.enter)="$event.preventDefault()" [formGroup]="form">
                                <div *ngFor="let field of visibleFields"
                                    [ngClass]="{'hidden-form-control': hideFormControlsOnUI && field.fieldCode != 'DOCUMENT_TYPE_VALUE' }"
                                    class="form-row no-padding-bottom invoice-form" fxFlex="1 0 {{flexValue}}">
                                    <app-element [fieldList]="visibleFields" [element]="field" [form]="form"
                                        (readFocusEvent)="readFocus($event)"
                                        (onDocTypeChange)="documentTypeChanged($event)"
                                        (onChipSelect)="chipSelectionChanged($event)"
                                        (onChipRemove)="clearChipFocus($event)"
                                        (clearClickEvent)="clear($event)"></app-element>
                                </div>
                            </form>
                        </mat-expansion-panel>
                    </as-split-area>
                    <as-split-area [size]="65" class="image-split-area" style="overflow: hidden;">
                        <!-- <div id="docViewer" class="gt-container-xxl"> -->
                        <!-- Image controls toolbar -->
                        <mat-expansion-panel id="docViewer" hideToggle [expanded]="true" class="image-toolbar-panel">
                            <mat-expansion-panel-header #uniquePanelNameImg (click)="uniquePanelNameImg._toggle()">
                                <mat-panel-title> {{tr("spdf.app.module.bu.validation.image.header")}}
                                </mat-panel-title>
                                <mat-panel-description class="image-panel-description">
                                    <div *ngIf="navigationControlExpanded">
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            [disabled]="paginator.page==startIndex || processingTask || skippingTask || loadingImage"
                                            (click)="changePage(paginatorNavigation.FIRST)"
                                            matTooltip="{{tr('spdf.app.tooltips.imgControls.first')}}"
                                            attr.aria-label="{{tr('spdf.app.tooltips.imgControls.first')}}">
                                            <mat-icon fontSet="far" fontIcon="fa-arrow-to-left" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            [disabled]="paginator.page==startIndex || processingTask || skippingTask || loadingImage"
                                            (click)="changePage(paginatorNavigation.PREVIOUS)"
                                            matTooltip="{{tr('spdf.app.tooltips.imgControls.previous')}}"
                                            attr.aria-label="{{tr('spdf.app.tooltips.imgControls.previous')}}">
                                            <mat-icon fontSet="far" fontIcon="fa-angle-left" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <!-- </div> -->
                                        <input matInput class="paginator-page-text" readonly
                                            value="{{this.paginator.page}} / {{paginator.endIndex}}">
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            [disabled]="paginator.page==imageCount || processingTask || skippingTask || loadingImage"
                                            (click)="changePage(paginatorNavigation.NEXT)"
                                            matTooltip="{{tr('spdf.app.tooltips.imgControls.next')}}"
                                            attr.aria-label="{{tr('spdf.app.tooltips.imgControls.next')}}">
                                            <mat-icon fontSet="far" fontIcon="fa-angle-right" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            [disabled]="paginator.page==imageCount || processingTask || skippingTask || loadingImage"
                                            (click)="changePage(paginatorNavigation.LAST)"
                                            matTooltip="{{tr('spdf.app.tooltips.imgControls.last')}}"
                                            attr.aria-label="{{tr('spdf.app.tooltips.imgControls.last')}}">
                                            <mat-icon fontSet="far" fontIcon="fa-arrow-to-right"
                                                class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                    </div>
                                </mat-panel-description>
                                <div class="image-panel-controls">
                                    <div class="gt-additional-info-content" *ngIf="imgControlExpanded">
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            (click)="zoomOut(false)"
                                            [disabled]="processingTask || skippingTask || loadingImage"
                                            matTooltip="{{tr('spdf.app.tooltips.imgControls.zoomOut')}}"
                                            attr.aria-label="{{tr('spdf.app.tooltips.imgControls.zoomOut')}}">
                                            <mat-icon fontSet="far" fontIcon="fa-search-minus" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            (click)="zoomIn()"
                                            [disabled]="processingTask || skippingTask || loadingImage"
                                            matTooltip="{{tr('spdf.app.tooltips.imgControls.zoomIn')}}"
                                            attr.aria-label="{{tr('spdf.app.tooltips.imgControls.zoomIn')}}">
                                            <mat-icon fontSet="far" fontIcon="fa-search-plus" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <mat-divider vertical style="display: inline;" class="image-toolbar-separator">
                                        </mat-divider>
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            (click)="rotate('left')"
                                            [disabled]="processingTask || skippingTask || loadingImage"
                                            matTooltip="{{tr('spdf.app.tooltips.imgControls.r2l')}}"
                                            attr.aria-label="{{tr('spdf.app.tooltips.imgControls.r2l')}}">
                                            <mat-icon fontSet="far" fontIcon="fa-undo" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            (click)="rotate('right')"
                                            [disabled]="processingTask || skippingTask || loadingImage"
                                            matTooltip="{{tr('spdf.app.tooltips.imgControls.r2r')}}"
                                            attr.aria-label="{{tr('spdf.app.tooltips.imgControls.r2r')}}">
                                            <mat-icon fontSet="far" fontIcon="fa-redo" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <mat-divider vertical style="display: inline;" class="image-toolbar-separator">
                                        </mat-divider>
                                        <button mat-icon-button color="primary" [matMenuTriggerFor]="moreOptionsMenu"
                                            class="imageActionIcon" [matMenuTriggerRestoreFocus]="false"
                                            [disabled]="processingTask || skippingTask"
                                            matTooltip="{{tr('spdf.app.tooltips.moreActions')}}"
                                            attr.aria-label="{{tr('spdf.app.tooltips.moreActions')}}">
                                            <mat-icon fontSet="far" fontIcon="fa-ellipsis-v" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <mat-menu #moreOptionsMenu="matMenu" yPosition="below" [overlapTrigger]="false"
                                            (click)="$event.stopPropagation();">
                                            <button mat-menu-item (click)="fitToWidth()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.module.bu.validation.image.text.fitToWidth")}}
                                            </button>
                                            <button mat-menu-item (click)="fitToHeight()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.module.bu.validation.image.text.fitToHeight")}}</button>
                                            <button mat-menu-item [disabled]="processingTask || skippingTask"
                                                (click)="downloadInvoice()">
                                                {{tr("spdf.app.tooltips.imgControls.downloadpdf")}}</button>
                                        </mat-menu>
                                    </div>
                                    <!-- <div class="gt-titlebar-button-group" fxHide fxShow.lt-md> -->
                                    <div class="gt-additional-info-content"
                                        *ngIf="imgControlHidden && navigationControlExpanded">
                                        <button mat-icon-button class="imageActionIcon" color="primary"
                                            [matMenuTriggerFor]="moreOptionsMenuResized"
                                            [matMenuTriggerRestoreFocus]="false"
                                            matTooltip="{{tr('spdf.app.tooltips.moreActions')}}"
                                            [disabled]="processingTask || skippingTask"
                                            attr.aria-label="{{tr('spdf.app.tooltips.moreActions')}}">
                                            <mat-icon fontSet="far" fontIcon="fa-ellipsis-v" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <mat-menu #moreOptionsMenuResized="matMenu" yPosition="below"
                                            [overlapTrigger]="false" (click)="$event.stopPropagation();">
                                            <button mat-menu-item (click)="zoomOut(false)" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.tooltips.imgControls.zoomOut")}}</button>
                                            <button mat-menu-item (click)="zoomIn()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.tooltips.imgControls.zoomIn")}} </button>
                                            <button mat-menu-item (click)="fitToWidth()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.module.bu.validation.image.text.fitToWidth")}}</button>
                                            <button mat-menu-item (click)="fitToHeight()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.module.bu.validation.image.text.fitToHeight")}}</button>
                                            <mat-divider></mat-divider>
                                            <button mat-menu-item (click)="rotate('left')" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.tooltips.imgControls.r2l")}}</button>
                                            <button mat-menu-item (click)="rotate('right')" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.tooltips.imgControls.r2r")}}</button>
                                            <button mat-menu-item [disabled]="processingTask || skippingTask"
                                                (click)="downloadInvoice()">
                                                {{tr("spdf.app.tooltips.imgControls.downloadpdf")}}</button>
                                        </mat-menu>
                                    </div>
                                    <div class="gt-additional-info-content"
                                        *ngIf="imgControlHidden && !navigationControlExpanded">
                                        <button mat-icon-button class="imageActionIcon" color="primary"
                                            [matMenuTriggerFor]="moreOptionsMenuResized"
                                            [matMenuTriggerRestoreFocus]="false"
                                            matTooltip="{{tr('spdf.app.tooltips.moreActions')}}"
                                            [disabled]="processingTask || skippingTask"
                                            attr.aria-label="{{tr('spdf.app.tooltips.moreActions')}}">
                                            <mat-icon fontSet="far" fontIcon="fa-ellipsis-v" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <mat-menu #moreOptionsMenuResized="matMenu" yPosition="below"
                                            [overlapTrigger]="false" (click)="$event.stopPropagation();">
                                            <button mat-menu-item (click)="changePage(paginatorNavigation.FIRST)"
                                                *ngIf="!loadingImage"
                                                [disabled]="paginator.page==startIndex || processingTask || skippingTask || loadingImage">
                                                {{tr("spdf.app.tooltips.imgControls.first")}}</button>
                                            <button mat-menu-item (click)="changePage(paginatorNavigation.LAST)"
                                                *ngIf="!loadingImage"
                                                [disabled]="paginator.page==imageCount || processingTask || skippingTask || loadingImage">
                                                {{tr("spdf.app.tooltips.imgControls.last")}} </button>
                                            <button mat-menu-item (click)="changePage(paginatorNavigation.NEXT)"
                                                *ngIf="!loadingImage"
                                                [disabled]="paginator.page==imageCount || processingTask || skippingTask || loadingImage">
                                                {{tr("spdf.app.tooltips.imgControls.next")}}</button>
                                            <button mat-menu-item (click)="changePage(paginatorNavigation.PREVIOUS)"
                                                *ngIf="!loadingImage"
                                                [disabled]="paginator.page==startIndex || processingTask || skippingTask || loadingImage">
                                                {{tr("spdf.app.tooltips.imgControls.previous")}}</button>
                                            <mat-divider></mat-divider>
                                            <button mat-menu-item (click)="zoomOut(false)" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.tooltips.imgControls.zoomOut")}}</button>
                                            <button mat-menu-item (click)="zoomIn()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.tooltips.imgControls.zoomIn")}} </button>
                                            <button mat-menu-item (click)="fitToWidth()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.module.bu.validation.image.text.fitToWidth")}}</button>
                                            <button mat-menu-item (click)="fitToHeight()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.module.bu.validation.image.text.fitToHeight")}}</button>
                                            <mat-divider></mat-divider>
                                            <button mat-menu-item (click)="rotate('left')" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.tooltips.imgControls.r2l")}}</button>
                                            <button mat-menu-item (click)="rotate('right')" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{tr("spdf.app.tooltips.imgControls.r2r")}}</button>
                                            <button mat-menu-item [disabled]="processingTask || skippingTask"
                                                (click)="downloadInvoice()">
                                                {{tr("spdf.app.tooltips.imgControls.downloadpdf")}}</button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                        <div *ngIf="isImageContentLoading" class="gt-spinner-container">
                            <mat-progress-spinner [diameter]="32" mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                        <div class="canvas-container" [hidden]="loadingImage">
                            <canvas style="position:relative;" id="canvas" class="img-viewer-body">
                                {{tr("spdf.app.module.bu.validation.image.text.canvasNotSupported")}}
                            </canvas>
                        </div>
                        <div [hidden]="!loadingImage"
                            class="empty-state-custom align-center gt-empty-state-panel-body-container">
                            <div class="gt-row">
                                <p>{{tr('spdf.app.module.bu.validation.image.error')}}</p>
                            </div>
                            <div class="gt-row">
                                <button mat-stroked-button (click)="downloadInvoice()"
                                    color="primary">{{tr('spdf.app.tooltips.imgControls.downloadpdf')}}</button>
                            </div>
                        </div>
                        <!-- </div> -->
                    </as-split-area>
                </as-split>
            </as-split-area>
            <as-split-area *ngIf="taskResponse?.isLineItemsActive" [size]="splitAreaSizes[1]" [minSize]="7">
                <div class="inner-container">
                    <invoice-lines [lineItems]="invoiceLineItems" (updatedLineItems)="updatedInvoiceLineItems($event)"
                        (isLineItemsValid)="_isLineItemsValid($event)" (readFocusOnCanvas)="readFocusForLineItems($event)"></invoice-lines>
                </div>
            </as-split-area>
        </as-split>
    </div>
    <div class="empty-state-section container" *ngIf="taskResponse?.allDocsValidated">
        <mat-tab-group animationDuration="0ms" class="empty-tab-group gt-example-tab-group">
            <mat-tab>
                <div class="gt-empty-state-tab">
                    <div class="gt-row">
                        <p class="gt-lead-text">{{tr('spdf.app.module.document.validation.states.empty.message')}}</p>
                    </div>
                    <div class="gt-row">
                        <p>{{tr('spdf.app.module.document.validation.states.empty.description')}}</p>
                    </div>
                    <div class="gt-row gt-button-group-row" [ngClass.xs]="{'gt-row gt-button-group-row':false}">
                        <button mat-button [ngClass.xs]="{'gt-row':true}" color="primary" (click)="reloadPage()">
                            {{tr('spdf.app.actions.reload')}}
                        </button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>