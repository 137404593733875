import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import { LocalizationService } from './localization.service';
import { SessionService } from './session.service';
import { AppConfig } from '../app.config';
@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

    constructor(private router: Router, private common: CommonService, private localization: LocalizationService, private _sessionService: SessionService) { }
    /**
     * Authorization cookie interceptor.
     *
     * @param request The incoming request.
     * @param next The next handler.
     * @returns The handled request.
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let addCmosEnvInParams = false;
        if (this.common.getCmosEnv() && request.url.includes(AppConfig.APP_BASE_API_URL)) {
            addCmosEnvInParams = true;
        }
        //enable auth via cookies
        const clonedReq = request.clone({
            headers: request.headers.set('Content-Type', 'application/json'),
            params: addCmosEnvInParams ? request.params.append('cmosEnv', this.common.getCmosEnv()) : request.params,
            withCredentials: true
        });
        return <any> next.handle(clonedReq).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && ((event.status > 0 && event.status < 400) || (event.status >= 500))) {
                this._sessionService.emitChange(AppConfig.RESET_SESSION_TIME_IN_MINS);
            }
        })).pipe(
            catchError(async (error: HttpErrorResponse) => {
                if (error.status == 401) {
                    this.router.navigate(["login"]);
                } else if (error.status == 403) {
                    await this.localization.setLang(error.error.locale);
                    this.common.showForbiddenDialog();
                }
                // return throwError(() => error);
                throw error;
            })
        );
    }
}
