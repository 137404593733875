<mat-expansion-panel hideToggle [expanded]="true">
  <div class="container">
    <form>
      <mat-form-field fxFlex="{{flexValue < 50 ? 'auto' : '100'}}" class="chip-field-width" appearance="legacy"
        floatLabel="always">
        <mat-label>{{tr('spdf.app.module.buup.user')}}</mat-label>
        <mat-select #mySelect multiple [(value)]="selectedUsers" class="gt-multi-select"
          panelClass="gt-multi-select-lookup-panel" disableOptionCentering="true"
          placeholder="{{tr('spdf.app.module.buup.select')}}"
          (openedChange)="panelOpened(mySelect); searchField.focus()">
          <!-- Catch keypresses, so that navigating chips does not open panel. -->
          <mat-select-trigger *ngIf="selectedArray.length > 0" (click)="$event.stopPropagation()"
            (keydown)="$event.stopPropagation()">
            <div class="gt-multi-select-chip-list-wrapper">

              <mat-chip-list gt-reveal *ngIf="!mySelect.panelOpen" (click)="mySelect.open()">
                <!-- Note: gt-reveal requires that each chip has a data-reveal attribute. -->
                <mat-chip (keydown.delete)="removeUser(selectedUser)" (keydown.backspace)="removeUser(selectedUser)"
                  *ngFor="let selectedUser of selectedArray; index as i" [attr.data-reveal]="i">
                  {{ selectedUser.name }}

                  <mat-icon matChipRemove matTooltip="{{tr('spdf.app.tooltips.remove')}}"
                    attr.aria-label="{{tr('spdf.app.tooltips.remove')}}" (click)="removeUser(selectedUser)"> cancel
                  </mat-icon>
                  <!-- Need to check if button is needed as the parent elements-->
                </mat-chip>

                <!-- Reveal component -->
                <mat-basic-chip class="gt-reveal-others" [matMenuTriggerFor]="basicUsersMenu" disabled>
                  <a class="gt-link-secondary">
                    <span class="gt-reveal-count"></span>
                    <span class="gt-reveal-singular">{{tr('spdf.app.module.buup.other')}}</span>
                    <span class="gt-reveal-plural">{{tr('spdf.app.module.buup.others')}}</span>
                  </a>
                </mat-basic-chip>

                <mat-menu #basicUsersMenu="matMenu" yPosition="below" class="gt-menu-popover">
                  <div class="gt-reveal-container">
                    <div class="mat-body-1" *ngFor="let chip of selectedUsers; index as i" [attr.data-reveal]="i"
                      (click)="$event.stopPropagation()">
                      {{ chip.name }}
                    </div>
                  </div>
                </mat-menu>
                <!-- End reveal component-->
              </mat-chip-list>
            </div>

            <button *ngIf="!mySelect.panelOpen" class="gt-form-field-clear" mat-icon-button
              matTooltip="{{tr('spdf.app.tooltips.clear')}}" attr.aria-label="{{tr('spdf.app.tooltips.clear')}}"
              (click)="removeUsers(); $event.stopPropagation()"
              (keydown.enter)="removeUsers(); $event.stopPropagation()">
              <mat-icon> cancel </mat-icon>
            </button>
          </mat-select-trigger>

          <!-- SEARCH FIELD -->
          <div class="gt-multi-select-search">
            <input #searchField [formControl]="searchControl" placeholder="{{tr('spdf.app.module.buup.search')}}"
              matInput autocomplete="off" (keydown)="handleInput($event)" />

            <div class="gt-select-panel-item gt-item-top-row gt-search-result-text">
              {{ selectedArray.length > 0 ? selectedArray.length : 0 }} {{tr('spdf.app.module.buup.selected')}}
            </div>

            <div class="gt-select-panel-item gt-item-second-row"
              *ngIf="(filteredUsers | async) && (filteredUsers | async)!.length">
              {{ (filteredUsers | async)?.length }} {{tr('spdf.app.module.buup.results.found')}}
            </div>

            <div class="gt-select-panel-item gt-item-second-row gt-search-result-text"
              *ngIf="(filteredUsers | async)?.length === 0 && searchField.value !== null">
              {{tr('spdf.app.module.buup.no.results.found')}}
            </div>
          </div>

          <mat-option *ngFor="let user of filteredUsers | async" [value]="user"
            (onSelectionChange)="selectionChanged($event, user)">
            <span innerHTML="{{ user.name + ' (' + user.email + ')' | highlightSearch: searchField.value }}">
            </span>
          </mat-option>

        </mat-select>
      </mat-form-field>
    </form>

    <mat-panel-description fxLayoutGap="8px">
      <button mat-stroked-button color="primary" (click)="cancelEditingUnit(true)" [disabled]="savingUnitChanges">
        {{tr('spdf.app.module.buup.cancel')}}
      </button>
      <button mat-flat-button color="primary" attr.aria-label="{{tr('spdf.app.module.buup.save')}}"
        (click)="saveChanges()" [disabled]="savingUnitChanges">
        <div class="gt-spinner-container" *ngIf="savingUnitChanges">
          <mat-progress-spinner [diameter]="20" mode="indeterminate"> </mat-progress-spinner>
        </div>
        <span *ngIf="!savingUnitChanges"> {{tr('spdf.app.module.buup.save')}} </span>
      </button>
    </mat-panel-description>
  </div>
</mat-expansion-panel>