import { AbstractControl } from '@angular/forms';
import { GlobalizeService } from '../app-services/globalize.service';
import { AppConfig } from '../app.config';

export class DecimalValidator {
    static decimalValidatorForValue(value: any) {
        let result = GlobalizeService.unformatAnyNumber(value);
        let isInvalid;
        if (result) {
            let asciiReplacedVal = value.replaceAll(String.fromCharCode(160), String.fromCharCode(32));
            let numStruct = GlobalizeService.getNumberStructure(AppConfig.USER_CULTURE || AppConfig.BROWSER_CULTURE);
            //treat ascii val equivalent to the one without decimal values
            let asciiReplacedValEquivalent;
            //formatted var to store formatted value if user inputs val w/o thousand separator
            let formattedLocalAsciiVal;
            //check if number has invalid symbols
            let hasInvalidChars = false;
            const patternStr = "^-?(?!0)(?:\\d+|\\d{1,3}(?:" + numStruct.thousand + "\\d{3})+)(?:\\" + numStruct.decimal + "\\d+)?$";
            const pattern = new RegExp(patternStr);
            if (!pattern.test(asciiReplacedVal)) {
                hasInvalidChars = true;
            }
            let decimalIndex = asciiReplacedVal.indexOf(numStruct.decimal);
            if (decimalIndex != -1) {
                if (asciiReplacedVal.split(numStruct.decimal)[1] == 0) {
                    asciiReplacedValEquivalent = asciiReplacedVal.split(numStruct.decimal)[0];
                } else if (asciiReplacedVal.split(numStruct.decimal)[1][asciiReplacedVal.split(numStruct.decimal)[1].length - 1] == 0) {
                    asciiReplacedValEquivalent = asciiReplacedVal.substring(0, asciiReplacedVal.length - 1);
                }
            }
            let localeStr = Number(result).toLocaleString(
                AppConfig.USER_CULTURE || AppConfig.BROWSER_CULTURE,
                { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }).replaceAll(/\xA0/g, " ");
            //format local ascii value if it contains more than 3 whole numbers
            if (!hasInvalidChars && result.split(numStruct.decimal)[0].length > 3) {
                formattedLocalAsciiVal = localeStr;
            }
            //control invalid if locale parsed string doesn't match with 
            // corresponding user entered value being parsed to culture value or its has invalid culture symbols
            isInvalid = localeStr != asciiReplacedVal && localeStr != asciiReplacedValEquivalent
                && localeStr != formattedLocalAsciiVal && hasInvalidChars;
            if (!value) {
                return null;
            }
        }
        if ((isNaN(Number(result)) || isInvalid)) {
            return { 'decimalValidator': true };
        }
        return null;
    }
    static decimalValidator(AC: AbstractControl) {
        if (AC) {
            return DecimalValidator.decimalValidatorForValue(AC.value);
        } else return null;
    }
}
