<div mat-dialog-title fxlayout="row">
    <div>{{tr('spdf.app.module.bu.dialog.sendDefault.title')}}</div>
    <div class="gt-dialog-close">
        <button mat-icon-button color="primary" attr.aria-label="{{tr('spdf.app.tooltips.close')}}"
            matTooltip="{{tr('spdf.app.tooltips.close')}}" mat-dialog-close>
            <mat-icon fontSet="far" fontIcon="fa-times"> </mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>
    <div class="data-field">
        {{tr(data.messageKey1)}}
    </div>
    <div class="data-field">
        {{tr(data.messageKey2)}}
    </div>
    <div class="data-field">
        {{tr(data.messageKey3)}}
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close (click)="sendDefault()">
        {{tr('spdf.app.module.bu.actions.sendDefault')}}
    </button>
    <button mat-flat-button color="primary" (click)="goBack()">
        {{tr('spdf.app.module.buup.button.goBack')}}
    </button>
</div>