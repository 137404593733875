export const environment = {
  production: true,
  stage: 'dev',
  multiInvoiceFeatureFlag: true,
  invoiceLinesFeatureFlag: true,
  approvalFlowFeatureFlag: true,
  app_base_api_url: 'https://api.smartpdf.baswaredev.com',
  app_base_url: 'http://smartpdf.baswaredev.com',
  bw_access_url: 'https://access.baswaredev.com',
  appVersion: require('../../package.json').version
};
