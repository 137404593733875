import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { LocalizationService } from 'src/app/app-services/localization.service';

@Component({
  selector: 'app-clear-all-users',
  templateUrl: './clear-all-users.component.html',
  styleUrls: ['./clear-all-users.component.scss']
})
export class ClearAllUsersComponent extends BaseTranslationService implements OnInit {

  constructor(private dialogRef: MatDialogRef<ClearAllUsersComponent>, localization: LocalizationService) {
    super(localization);
  }

  ngOnInit(): void {
  }

  clearAll() {
    this.dialogRef.close({
      ok: true
    });
  }

}
