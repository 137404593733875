<div mat-dialog-title fxlayout="row">
    <div>{{tr(title)}}</div>
    <div class="gt-dialog-close">
        <button mat-icon-button color="primary" attr.aria-label="{{tr('spdf.app.tooltips.close')}}"
            matTooltip="{{tr('spdf.app.tooltips.close')}}" mat-dialog-close [disabled]="deleting">
            <mat-icon fontSet="far" fontIcon="fa-times"> </mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>
    <mat-toolbar #bannerE *ngIf="showErrorBanner" class="mb-16 gt-banner gt-banner-error">
        <mat-icon fontSet="fas" fontIcon="fa-exclamation-circle" class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden"> Error</span>
        <span class="gt-banner-content mat-body-1">
            <span class="gt-banner-messageblock">{{tr("spdf.app.module.bu.internal.error")}}</span>
        </span>
        <button mat-icon-button attr.aria-label="{{tr('spdf.app.tooltips.close')}}" (click)="showErrorBanner = false"
            color="gt-color-info" class="gt-banner-close-btn">
            <mat-icon fontSet="far" fontIcon="fa-times" aria-hidden="false" class="gt-icon"
                matTooltip="{{tr('spdf.app.tooltips.close')}}">
            </mat-icon>
        </button>
    </mat-toolbar>
    {{tr(message)}} {{data.rowData.domain}}?
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close attr.aria-label="{{tr('spdf.app.module.buup.cancel')}}"
        [disabled]="deleting">
        {{tr('spdf.app.module.buup.cancel')}}
    </button>
    <button mat-flat-button color="primary" attr.aria-label="{{tr(okButtonText)}}" (click)="delete()"
        [disabled]="deleting || showErrorBanner">
        <div class="gt-spinner-container" *ngIf="deleting">
            <mat-progress-spinner diameter="20" mode="indeterminate"> </mat-progress-spinner>
        </div>
        <span *ngIf="!deleting">{{tr(okButtonText)}}</span>
    </button>
</div>