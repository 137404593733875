<div mat-dialog-title fxlayout="row" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div>{{tr('spdf.app.dialog.supplier.title')}}</div>
  <div class="gt-dialog-close">
    <button mat-icon-button color="primary" aria-label="{{tr('spdf.app.tooltips.close')}}"
      matTooltip="{{tr('spdf.app.tooltips.close')}}" mat-dialog-close>
      <mat-icon fontSet="far" fontIcon="fa-times"> </mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content>
  <div fxLayout="row wrap" class="supplier-dialog">
    <div fxFlex="33">
      <mat-form-field appearance="legacy" class="gt-form-field-without-label gt-quicksearch-input filterWidth mb-16"
        autocomplete="off" floatLabel="always">
        <mat-label id="gt-mat-label-1"></mat-label>
        <input matInput [(ngModel)]="searchText" (keyup)="onFilterTextChange()" autocomplete="off"
          aria-labelledby="gt-mat-label-1" placeholder="{{tr('spdf.app.dialog.supplier.searchPlaceholder')}}">

        <button class="gt-form-field-clear" mat-icon-button matSuffix *ngIf="searchText" (click)="clearSearchText()"
          matTooltip="{{tr('spdf.app.tooltips.clear')}}" attr.aria-label="{{tr('spdf.app.tooltips.clear')}}">
          <mat-icon> cancel </mat-icon>
        </button>

        <button mat-icon-button matSuffix disabled class="gt-search-icon"
          attr.aria-label="{{tr('spdf.app.module.buup.searchIcon')}}">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <div fxFlex="row wrap" class="full-width-without-margin">
        <div fxFlex="33">
          <div class="supplier-list" *ngIf="!noSupplier">
            <gt-grid [config]="gridConfig" [enableKeyboardEvents]="true"></gt-grid>
          </div>
          <div *ngIf="noSupplier" class="empty-supplier-list apply-border">
            <mat-expansion-panel hideToggle [expanded]="true" class="gt-empty-state-panel full-height mb-0">
              <div class="gt-empty-state-panel-body-container gt-centered-content full-height" style="height: 100%;">
                <p> {{tr('spdf.app.dialog.supplier.noSupplier')}} </p>
              </div>
            </mat-expansion-panel>
          </div>
        </div>

        <div fxFlex="67" class="border">
          <div class="supplierInfo">
            {{supplierInfo.supplierName}}
            <diV class="supplierSubInfo mat-hint"> | {{supplierInfo.supplierCode}} </diV>
          </div>
          <mat-tab-group class="tabs border-bottom ">
            <mat-tab label="{{tr('spdf.app.dialog.supplier.bankAccounts.heading')}}">
              <div class="bank-details">

                <div class="tab-content" *ngIf="supplierInfo.bankAccounts && supplierInfo.bankAccounts.length > 0">
                  <mat-table [dataSource]="supplierInfo.bankAccounts" class="gt-selection-table">
                    <ng-container matColumnDef="iban">
                      <mat-header-cell *matHeaderCellDef>
                        {{tr('spdf.app.dialog.supplier.bankAccounts.subheading1')}}</mat-header-cell>
                      <mat-cell *matCellDef="let row"> {{row.iban}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="number">
                      <mat-header-cell *matHeaderCellDef> {{tr('spdf.app.dialog.supplier.bankAccounts.subheading2')}}
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row"> {{row.number}} </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="['iban', 'number']"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: ['iban', 'number']" (click)="rowSelect(row)"
                      (dblclick)="bankRowDoubleClick(row)" [class.gt-row-highlight]="rowSelection.isSelected(row)">
                    </mat-row>
                  </mat-table>
                </div>
                <div class="gt-empty-state-tab height-fill"
                  *ngIf="!supplierInfo.bankAccounts || supplierInfo.bankAccounts.length==0">
                  <div class="gt-flex-child-container">
                    <p> {{tr('spdf.app.dialog.supplier.bankAccounts.emptyState')}} </p>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
          <mat-tab-group class="tabs ">
            <mat-tab label="{{tr('spdf.app.dialog.supplier.additionalInfo.heading')}}">
              <div id="additional-info" class="tab-content additional-info">
                <mat-expansion-panel hideToggle [expanded]="true">
                  <form name="additionalInfo" class="data-field" fxLayout="row wrap" fxLayoutGap="24px grid">
                    <div class="form-row no-padding-bottom" fxFlex="{{flexValue}}">
                      <mat-form-field appearance="legacy" aria-readonly="true" floatLabel="always">
                        <mat-label>{{tr('spdf.app.dialog.supplier.additionalInfo.vat')}}</mat-label>
                        <input matInput name="vat" [ngModel]="supplierInfo.vat" placeholder="–" readonly disabled>
                      </mat-form-field>
                    </div>
                    <div class="form-row no-padding-bottom" fxFlex="{{flexValue}}">
                      <mat-form-field appearance="legacy" aria-readonly="true" floatLabel="always">
                        <mat-label>{{tr('spdf.app.dialog.supplier.additionalInfo.invoiveAddress')}}</mat-label>
                        <input matInput name="einvoiceAddress" [ngModel]="supplierInfo.einvoiceAddress" placeholder="–"
                          readonly disabled>
                      </mat-form-field>
                    </div>
                    <div class="form-row no-padding-bottom" fxFlex="{{flexValue}}">
                      <mat-form-field appearance="legacy" aria-readonly="true" floatLabel="always">
                        <mat-label>{{tr('spdf.app.dialog.supplier.additionalInfo.phone')}}</mat-label>
                        <input matInput name="phone" [ngModel]="supplierInfo.phone" placeholder="–" readonly disabled>
                      </mat-form-field>
                    </div>
                    <div class="form-row no-padding-bottom" fxFlex="{{flexValue}}">
                      <mat-form-field appearance="legacy" aria-readonly="true" floatLabel="always">
                        <mat-label>{{tr('spdf.app.dialog.supplier.additionalInfo.address')}}</mat-label>
                        <textarea rows="3" matInput name="address" [ngModel]="supplierInfo.address" placeholder="–"
                          readonly disabled> </textarea>
                      </mat-form-field>
                    </div>
                  </form>
                </mat-expansion-panel>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions class="mt-12">
  <button mat-stroked-button color="primary" mat-dialog-close aria-label="{{tr('spdf.app.module.buup.cancel')}}">
    {{tr('spdf.app.module.buup.cancel')}}
  </button>
  <button [disabled]="!canSubmit" mat-flat-button color="primary" mat-dialog-close aria-label="Select"
    (click)="selectSupplier()">
    {{tr('spdf.app.actions.select')}}
  </button>
</div>