import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { LocalizationService } from 'src/app/app-services/localization.service';
import { SmartpdfApiService } from 'src/app/app-services/smartpdf.api.service';
import { ConfigurationReport } from 'src/app/models/ConfigurationReport';

@Component({
  selector: 'app-approval-dialog',
  templateUrl: './approval-dialog.component.html',
})
export class ApprovalDialogComponent extends BaseTranslationService implements OnInit {

  comment = new FormControl('', { validators: Validators.required });
  sending: boolean;
  errorState: boolean = false;
  errorMessage: string;
  config: ConfigurationReport;
  disableActionButton: boolean = false;

  confirmButtonText = "spdf.app.module.buup.ok"
  title = ""

  constructor(private spdfApiService: SmartpdfApiService,
    localization: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ApprovalDialogComponent>) {

    super(localization);
    if (data) {
      this.title = data.title || this.title;
      this.confirmButtonText = data.confirmButtonText || this.confirmButtonText;
    }
  }

  ngOnInit(): void {
    this.config = this.data.config;
  }

  ok() {
    if (this.comment.invalid) {
      this.comment.markAsTouched();
      return;
    }
    this.sending = true;
    this.errorState = false;
    let body = { comment: this.comment.value };
    if (this.data.status == 'approved') {
      this.approve(body);
    } else {
      this.reject(body)
    }
  }

  approve(body: any) {
    this.spdfApiService.approveConfiguration(this.config.configurationId, body).subscribe({
      next: (data) => {
        this.sending = false;
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.sending = false;
        this.errorHandler(err);
      }
    })
  }

  reject(body: any) {
    this.spdfApiService.rejectConfiguration(this.config.configurationId, body).subscribe({
      next: (data) => {
        this.sending = false;
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.sending = false;
        this.errorHandler(err);
      }
    })
  }

  errorHandler(err: HttpErrorResponse) {
    this.errorState = true;
    if (err.status == 403) {
      this.disableActionButton = true;
      this.errorMessage = "spdf.app.module.smartPdf.error.authorization"
      return;
    }
    if (err.error && err.error.errorCode == "400.006") {
      this.disableActionButton = true;
    }
    if (err.error && err.error.errorMessage) {
      this.errorMessage = err.error.errorMessage;
    } else {
      this.errorMessage = 'spdf.app.module.smartPdf.error.serviceDown'
    }
  }

  trimInput() {
    if (this.comment.value) {
      this.comment.setValue(this.comment.value.trim());
    }
  }

}
