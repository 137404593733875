<div class="banner-bg">
    <mat-toolbar #bannerE [ngClass]="{'gt-banner-hidden': hideBanner }" class="gt-banner gt-banner-info">
        <mat-icon fontSet="fas" fontIcon="fa-info-circle" class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden">Information</span>
        <div class="gt-banner-content mat-body-1">
            <div class="gt-banner-messageblock">
                <ng-container *ngTemplateOutlet="messageTemplate"> </ng-container>
            </div>
            <span class="gt-banner-linkblock">
                <a class="gt-link-secondary" [routerLink]="secondaryLinkRoute" tabindex="0"
                    *ngIf="secondaryLink">{{tr(secondaryLink)}}</a>
                <a class="gt-link-primary" [routerLink]="primaryLinkRoute" tabindex="0"
                    *ngIf="primaryLink">{{tr(primaryLink)}}</a>
            </span>
        </div>
        <button mat-icon-button attr.aria-label="{{tr('spdf.app.tooltips.close')}}" (click)="hideBanner = true"
            color="gt-color-info" class="gt-banner-close-btn" tabindex="0">
            <mat-icon fontSet="far" fontIcon="fa-times" aria-hidden="false" class="gt-icon"
                matTooltip="{{tr('spdf.app.tooltips.close')}}">
            </mat-icon>
        </button>
    </mat-toolbar>
</div>