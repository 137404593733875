import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { GlobalizeService } from '../app-services/globalize.service';
import * as moment from 'moment';

export interface KeyValuePair {
    value: string;
    viewValue: string;
}

@Injectable()
export class AppUtils {

    rotationAngleInDegrees = 0;

    //set canvas rotation angle for persistence
    setRotateAngleInDegree(angleInDegree: any) {
        if (angleInDegree < 0) {
            this.rotationAngleInDegrees = 270;
        } else if (angleInDegree > 270) {
            this.rotationAngleInDegrees = 0;
        } else {
            this.rotationAngleInDegrees = angleInDegree;
        }
    }

    //convert degrees to radians
    degreesToRadians(angleInDegree: any) {
        return angleInDegree * (Math.PI / 180);
    }

    //convert radians to degrees
    radinsToDegrees(angleInRadian: any) {
        return angleInRadian * (180 / Math.PI);
    }

    //Get Sort parameters for business units
    GetBUSortParams() {
        let params = [];
        for (var key of Object.keys(AppConfig.SORT_PARAMS)) {
            let param = <KeyValuePair>{};
            param.value = key;
            param.viewValue = AppConfig.SORT_PARAMS[key];
            params.push(param);
        }
        return params;
    }

    static localizeDecimal(value: any) {
        //special case handling required due to different arabic numerals
        if (AppConfig.USER_CULTURE == 'ar-SA') {
            return GlobalizeService.formatNumber(
                GlobalizeService.unformatAnyNumber(value), AppConfig.USER_CULTURE).replaceAll(/\xA0/g, " ")
        } else {
            return Number(GlobalizeService.unformatAnyNumber(value)).toLocaleString(
                AppConfig.USER_CULTURE || AppConfig.BROWSER_CULTURE,
                { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }).replaceAll(/\xA0/g, " ");
        }
    }

    getMomenDateFormat(culture: string) {
        moment.locale(culture || AppConfig.BROWSER_CULTURE);
        let localeData = moment.localeData();
        return localeData.longDateFormat('L');
    }

    getMomentParsedDate(dateValue: string, dateFormat: string) {
        let momentParsedDate = moment(dateValue, dateFormat, true);
        let extractedDate;
        if (momentParsedDate.isValid()) {
            extractedDate = moment.utc(momentParsedDate,
                AppConfig.USER_CULTURE || AppConfig.BROWSER_CULTURE);
        } else {
            extractedDate = moment.utc(new Date(dateValue),
                AppConfig.USER_CULTURE || AppConfig.BROWSER_CULTURE);
        }
        if (extractedDate.isValid()) {
            dateValue = extractedDate.toISOString();
        }
        return dateValue;
    }
}