<div class="gt-container-xl">
    <mat-toolbar class="gt-titlebar">
        <div class="gt-titlebar-back-button">
            <button mat-icon-button attr.aria-label="{{tr('spdf.app.tooltips.return')}}" color="primary"
                (click)="goBack()">
                <mat-icon matTooltip="{{tr('spdf.app.tooltips.goBack')}}"
                    attr.aria-label="{{tr('spdf.app.tooltips.goBack')}}" fontSet="far"
                    fontIcon="fa-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="gt-titlebar-content-wrapper">
            <div class="gt-titlebar-left-content-wrapper">
                <div class="gt-titlebar-main-title">
                    <h1>{{tr('spdf.app.module.invoiceSettings.titlebar')}}</h1>
                </div>
            </div>
        </div>
    </mat-toolbar>

    <mat-expansion-panel hideToggle [expanded]="true">
        <mat-expansion-panel-header #uniquePanelName (click)="uniquePanelName._toggle()"
            (keydown.Enter)="uniquePanelName._toggle()" (keydown.Space)="uniquePanelName._toggle()">
            <mat-panel-title role="heading" aria-level="2">
                {{tr('spdf.app.module.invoiceSettings.panel.title')}}
                <span *ngIf="dataSource.data.length == 0" class="gt-badge gt-badge-muted">
                    {{dataSource.data.length}}
                </span>
                <span *ngIf="dataSource.data.length > 0" class="gt-badge gt-badge-secondary">
                    {{dataSource.data.length}}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div class="gt-mat-panel-actions-buttons-group">
                    <button mat-icon-button color="primary" attr.aria-label="{{tr('spdf.app.module.invoiceSettings.add')}}" class="gt-btn-more-actions"
                        (click)="addSender()">
                        <mat-icon fontSet="far" fontIcon="fa-plus icon button" matTooltip="{{tr('spdf.app.module.invoiceSettings.add')}}">
                        </mat-icon>
                    </button>
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngIf="!isContentLoading && dataSource.data.length==0"
            class="empty-state-custom gt-empty-state-panel-body-container" [ngClass.xs]="{'gt-centered-content':true}">
            <div class="gt-icon-panel-text">
                <p class="gt-lead-text mat-typography-p" [ngClass.xs]="{'gt-row':true}">
                    {{tr('spdf.app.module.invoiceSettings.no.data')}}
                </p>
                <div [ngClass.xs]="{'gt-row':true}">
                    <button mat-stroked-button color="primary" (click)="addSender()">
                        {{tr('spdf.app.module.invoiceSettings.add.sender')}}
                    </button>
                </div>
            </div>
        </div>

        <div [hidden]="!isContentLoading && dataSource.data.length==0">
            <div fxLayout="row wrap" fxFlexFill fxLayoutGap="24px">
                <mat-form-field fxFlex="{{flexValue < 66 ? 'auto' : '100'}}" appearance="legacy"
                    class="gt-form-field-without-label gt-quicksearch-input filterWidth mb-16" autocomplete="off"
                    floatLabel="always">
                    <mat-label id="gt-mat-label-1"></mat-label>
                    <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter(filterValue)" autocomplete="off"
                        aria-labelledby="gt-mat-label-1" placeholder="{{tr('spdf.app.search.placeholder')}}">

                    <button class="gt-form-field-clear" mat-icon-button matSuffix *ngIf="filterValue"
                        (click)="clearFilterValue()" matTooltip="{{tr('spdf.app.tooltips.clear')}}"
                        attr.aria-label="{{tr('spdf.app.tooltips.clear')}}">
                        <mat-icon> cancel </mat-icon>
                    </button>

                    <button mat-icon-button matSuffix disabled class="gt-search-icon"
                        attr.aria-label="{{tr('spdf.app.module.buup.searchIcon')}}">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
                <!-- <mat-divider *ngIf="flexValue<66" class="divider-filter" [vertical]="true"
                    role="separator"></mat-divider>

                <mat-form-field fxFlex="{{flexValue < 66 ? '194px' : '100'}}" appearance="legacy"
                    class="gt-sortby-select">
                    <label id="gt-mat-label">{{tr('spdf.app.module.bu.labels.sortBy')}}</label>
                    <mat-select [(ngModel)]="sortValue" aria-labelledby="gt-mat-label"
                        (selectionChange)="applySort(sortValue)">
                        <mat-option *ngFor="let item of sortByItems" [value]="item">
                            {{tr(item.value)}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>

            <div class="gt-loader-section" [hidden]="dataSource.filteredData.length==0">
                <div class="gt-loader-shade-normal loader-section-width" *ngIf="isContentLoading">
                    <mat-progress-bar mode="indeterminate" [value]="progressBarValue"
                        [bufferValue]="progressBarBufferValue">
                    </mat-progress-bar>
                </div>

                <mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z0" matSort="sort">
                    <ng-container matColumnDef="emailAddress">
                        <mat-header-cell
                            *matHeaderCellDef>{{tr('spdf.app.module.invoiceSettings.emailAddress')}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" class="gt-text-cell">
                            <ng-container>{{ element.domain }}</ng-container>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="splitMethod">
                        <mat-header-cell *matHeaderCellDef>{{tr('spdf.app.module.invoiceSettings.splitMethod')}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="gt-text-cell">
                            <ng-container>{{ sepeartors[element.splitMethod] }}</ng-container>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="text">
                        <mat-header-cell *matHeaderCellDef>{{tr('spdf.app.module.invoiceSettings.textToBeRecognized')}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="gt-text-cell">
                            <ng-container>
                                {{ element.textToBeRecognized ? element.textToBeRecognized : '-'}}
                            </ng-container>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef class="action-column">
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="action-column" #editIcon>
                            <button mat-icon-button color="primary" matTooltip="{{tr('spdf.app.tooltips.edit')}}"
                                aria-label="fa-pencil" (click)="editSender(element)">
                                <mat-icon fontSet="fas" fontIcon="fa-pencil"></mat-icon>
                            </button>
                            <button mat-icon-button [matMenuTriggerFor]="menu" color="primary"
                                class="gt-btn-more-actions" matTooltip="{{tr('spdf.app.tooltips.moreActions')}}"
                                (click)="$event.stopPropagation();">
                                <mat-icon fontSet="far" fontIcon="fa-ellipsis-v"></mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="$event.stopPropagation(); deleteSetting(element)">
                                    <span> {{tr('spdf.app.module.invoiceSettings.delete')}} </span>
                                </button>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>

                    <mat-row *matRowDef="let row; columns: columnsToDisplay;" class="gt-drillin-row">
                    </mat-row>
                </mat-table>
            </div>
            <div [hidden]="dataSource.data.length<5 || dataSource.filteredData.length==0">
                <mat-divider class="adjust-table-element-in-panel"></mat-divider>
                <mat-paginator class="adjust-table-element-in-panel" [length]="dataSource.data.length"
                    [pageSize]="pageSize" [pageSizeOptions]="matPaginatorPageSizeOptions">
                </mat-paginator>
            </div>

        </div>
        <div *ngIf="dataSource.data.length>0 && dataSource.filteredData.length==0"
            class="empty-state-custom gt-empty-state-panel-body-container" [ngClass.xs]="{'gt-centered-content':true}">
            <div class="gt-icon-panel-text">
                <p class="gt-lead-text mat-typography-p" [ngClass.xs]="{'gt-row':true}">
                    {{tr('spdf.app.module.invoiceSettings.no.filteredData')}}
                </p>
            </div>
        </div>
    </mat-expansion-panel>
</div>