<mat-tab-group [selectedIndex]="1" animationDuration="0ms">
    <mat-tab select>
        <ng-template mat-tab-label>
            <mat-icon *ngIf="!isLineItemsValid$" color="error" fontSet="fas" fontIcon="fa-exclamation-circle"
                class="gt-icon">
            </mat-icon>
            {{tr('spdf.app.module.invoice.lines.tab.label')}}
            <span class="gt-badge gt-badge-secondary">
                {{_mappedLineItems.length}}
            </span>
        </ng-template>
        <ng-template matTabContent>
            <ng-container *ngIf="_mappedLineItems.length == 0">
                <mat-expansion-panel hideToggle [expanded]="true">
                    <div class="empty-state-custom gt-empty-state-panel-body-container"
                        [ngClass.xs]="{'gt-centered-content':true}">
                        <div class="gt-row">
                            <p> {{tr('spdf.app.module.invoice.lines.no.data')}} </p>
                        </div>
                        <div class="gt-row">
                            <button mat-stroked-button color="primary" (click)="addLineItems()">
                                {{tr('spdf.app.module.invoice.lines.add')}}
                            </button>
                        </div>
                    </div>
                </mat-expansion-panel>
            </ng-container>
            <ng-container *ngIf="_mappedLineItems.length > 0">
                <editable-validating-grid [rowData]="_mappedLineItems" [reloadGrid]="reloadGrid" (updatedRowData)="updatedRowData($event)"
                    (isGridValid)="_isLineItemsValid($event)" (readFocusOnCanvas)="_readFocusOnCanvas($event)"></editable-validating-grid>
            </ng-container>
        </ng-template>
    </mat-tab>
</mat-tab-group>