import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/app-services/api.service';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { CommonService } from 'src/app/app-services/common.service';
import { LocalizationService } from 'src/app/app-services/localization.service';

@Component({
  selector: 'app-delete-setting-dialog',
  templateUrl: './delete-setting-dialog.component.html',
  styleUrls: ['./delete-setting-dialog.component.scss']
})
export class DeleteSettingDialogComponent extends BaseTranslationService implements OnInit {

  okButtonText = "spdf.app.module.buup.ok"
  title = "spdf.app.module.invoiceSettings.addSender"
  message: string;
  deleting: boolean = false;
  showErrorBanner: boolean = false;

  constructor(private dialogRef: MatDialogRef<DeleteSettingDialogComponent>,
    private common: CommonService,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any, localization: LocalizationService) {
    super(localization);
    if (data) {
      this.title = data.title;
      this.okButtonText = data.okButtonText;
      this.message = data.message
    }
  }

  ngOnInit(): void {
  }

  delete() {
    this.deleting = true;
    // outside click should not close this modal
    this.dialogRef.disableClose = true;
    this.apiService.deleteMultiInvoiceSettings(this.data.rowData).subscribe({
      next: (data) => {
        this.deleting = false;
        this.dialogRef.close({ ok: true })
      },
      error: (err) => {
        this.deleting = false;
        this.dialogRef.disableClose = false;
        this.showErrorBanner = true;
        console.log(err);
      }
    })
  }

}
