import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


@Injectable()
export class SessionService {
    // Observable string sources
    private emitChangeSource = new Subject<any>();
    // Observable string streams
    changeEmitted$ = this.emitChangeSource.asObservable();
    // Service message commands
    emitChange(change: number) {
        this.emitChangeSource.next(change);
    }
}