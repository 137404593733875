import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/app-services/api.service';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { CommonService } from 'src/app/app-services/common.service';
import { receivingEmailDomains, receivingEmailaddressDomains } from 'src/app/app-services/const';
import { LocalizationService } from 'src/app/app-services/localization.service';

@Component({
  selector: 'app-invoice-sender-dialog',
  templateUrl: './invoice-sender-dialog.component.html',
  styleUrls: ['./invoice-sender-dialog.component.scss']
})
export class InvoiceSenderDialogComponent extends BaseTranslationService implements OnInit {

  okButtonText = "spdf.app.module.buup.ok"
  title = "spdf.app.module.invoiceSettings.addSender"
  public invoiceSenderForm: FormGroup;
  saving: boolean = false;
  showErrorBanner: boolean = false;
  existingSettings: any;

  formValues: any;
  splitMethodOptions = [
    { key: 'whitepage', value: "Blank page" },
    { key: 'barcode', value: "Barcode" }
  ]

  constructor(private dialogRef: MatDialogRef<InvoiceSenderDialogComponent>,
    private common: CommonService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any, localization: LocalizationService) {
    super(localization);
    if (data) {
      this.title = data.title;
      this.okButtonText = data.okButtonText;
      this.formValues = { ...data.rowData };
      this.existingSettings = data.existingData;
    }
  }


  ngOnInit(): void {
    this.buildForm();
    this.splitMethod?.valueChanges.subscribe(val => {
      if (val != 'barcode') {
        this.textToBeRecognized?.disable();
      } else {
        this.textToBeRecognized?.enable();
      }
      this.textToBeRecognized?.updateValueAndValidity();

    })
    if (this.data.edit) {
      this.setFormValues(this.formValues)
    }
  }

  buildForm() {
    this.invoiceSenderForm = this.formBuilder.group({
      domain: ['', [Validators.required, domainValidator(this.existingSettings, this.formValues)]],
      splitMethod: ["whitepage"],
      textToBeRecognized: [{ value: '', disabled: true }, Validators.required]
    })
  }

  domainKeyUp() {
    if (this.domain?.value.includes('@')) {
      this.domain.addValidators(Validators.email);
      this.domain.updateValueAndValidity();
    } else {
      this.domain?.removeValidators(Validators.email);
      this.domain?.updateValueAndValidity();
    }
  }

  setFormValues(formValues: any) {
    this.domain?.setValue(formValues.domain)
    this.splitMethod?.setValue(formValues.splitMethod)
    this.textToBeRecognized?.setValue(formValues.textToBeRecognized)
  }

  trimInput(e: any) {
    this.textToBeRecognized?.setValue(this.textToBeRecognized.value.trim())
  }

  ok() {
    this.invoiceSenderForm.markAllAsTouched();
    if (this.invoiceSenderForm.invalid) {
      return;
    }
    let body = this.invoiceSenderForm.value;
    this.saving = true;
    // outside click should not close this modal
    this.dialogRef.disableClose = true;
    let res: Observable<any>;
    if (this.data.edit) {
      res = this.update(body) // update existing record
    } else {
      res = this.add(body) // add a new record
    }
    res.subscribe({
      next: (data) => {
        this.saving = false;
        this.dialogRef.close({ ok: true })
      },
      error: (err) => {
        this.saving = false;
        this.dialogRef.disableClose = false;
        this.showErrorBanner = true;
        console.log(err);
      }
    })
  }

  add(body: any) {
    body["customerId"] = this.common.getBaswareCustomerId();
    body["configurationId"] = "ALL"
    return this.apiService.postMultiInvoiceSettings(body);
  }

  update(updatedValues: any) {
    this.formValues.domain = updatedValues.domain;
    this.formValues.splitMethod = updatedValues.splitMethod;
    this.formValues.textToBeRecognized = updatedValues.textToBeRecognized;
    return this.apiService.updateMultiInvoiceSettings(this.formValues);
  }

  get domain() {
    return this.invoiceSenderForm.get('domain')
  }
  get splitMethod() {
    return this.invoiceSenderForm.get('splitMethod')
  }
  get textToBeRecognized() {
    return this.invoiceSenderForm.get('textToBeRecognized')
  }
}


export function domainValidator(existingSettings: any, currentValue: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    if (isInvalidDomain(control)) {
      return { ValidationErrors: true, incorrectDomain: true }
    }

    // domain cannot be a receiving domain
    if (isReceivingDomain(control)) {
      return { ValidationErrors: true, receivingDomain: true }
    }

    //check if entered domain/email-address already exist
    if (existingSettings) {
      let domains = existingSettings?.map((setting: any) => setting.domain);
      if (Object.keys(currentValue).length > 0) {
        domains = domains.filter((d: any) => d.toLowerCase() != currentValue.domain.toLowerCase())
      }
      let alreadyExist = domains.find((d: string) => d.toLowerCase() == control.value.toLowerCase());
      return alreadyExist ? { ValidationErrors: true, alreadyExist: true } : null;
    }
    return null;
  }
}

function isInvalidDomain(control: AbstractControl) {
  // domain should end with one dot and at least 2 letters
  // should not contain any space
  let domainRegex = "^(?!\\.)([^. ]+\\.)*[^. ]+\\.[a-zA-Z]{2,}$";
  if (!new RegExp(domainRegex).test(control.value)) {
    return true;
  }
}

function isReceivingDomain(control: AbstractControl) {
  // regex to validate email address, it should not end with a receiving domain
  let receivingEmailAddressRegex = "^.*(?<!" + receivingEmailaddressDomains.join('|') + ")$";
  // regex to validate domain, it should not be a receiving domain
  let receivingEmailDomainRegex = "^(" + receivingEmailDomains.join('|') + ")$";

  if (control.hasValidator(Validators.email)) {
    if (!new RegExp(receivingEmailAddressRegex).test(control.value)) {
      return true;
    }
  } else if (new RegExp(receivingEmailDomainRegex).test(control.value)) {
    return true;
  }
}