import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseTranslationService } from '../app-services/base.translation.service';
import { Router } from '@angular/router';
import { CommonService } from '../app-services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../app-services/api.service';
import { LocalizationService } from '../app-services/localization.service';
import { MatTableDataSource } from '@angular/material/table';
import { SubSink } from 'subsink';
import { AppConfig } from '../app.config';
import { MatSort, Sort } from '@angular/material/sort';
import { InvoiceSenderDialogComponent } from './invoice-sender-dialog/invoice-sender-dialog.component';
import { DeleteSettingDialogComponent } from './delete-setting-dialog/delete-setting-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
declare function aptrinsic(t: any, s: any, u: any): any;

@Component({
  selector: 'app-multi-invoice-settings',
  templateUrl: './multi-invoice-settings.component.html',
  styleUrls: ['./multi-invoice-settings.component.scss']
})
export class MultiInvoiceSettingsComponent extends BaseTranslationService implements OnInit {

  dataSource = new MatTableDataSource<any>();
  isContentLoading: boolean = false;
  subs = new SubSink();
  flexValue: number;

  columnsToDisplay = ["emailAddress", "splitMethod", "text", "actions"];
  filterValue: string = '';

  matPaginatorPageSizeOptions = AppConfig.MATERIAL_PAGINATOR_PAGE_SIZE_OPTIONS;
  pageSize = 20;

  progressBarValue = AppConfig.PROGRESS_BAR_VALUE;
  progressBarBufferValue = AppConfig.PROGRESS_BAR_BUFFER_VALUE;
  sortByItems = sortByItems;
  sortValue: any = this.sortByItems[0];

  sepeartors: any = {
    'whitepage': "Blank page",
    'barcode': "Barcode"
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.applySort(this.sortValue);
    this.dataSource.filterPredicate = this.tableFilter();
    this.dataSource.paginator = this.paginator;
    aptrinsic('track', 'smartPdfSplittingRulesPageLoaded', { "configuration": 0 });
  }

  constructor(public router: Router,
    public common: CommonService,
    public dialog: MatDialog,
    public apiService: ApiService,
    localization: LocalizationService) {
    super(localization);
    this.subs.add(this.common.flexValue.subscribe(
      value => { this.flexValue = value }
    ))
  }

  ngOnInit(): void {
    if (!environment.multiInvoiceFeatureFlag) {
      this.goBack();
    }
    this.getInvoiceSettings("ALL");
  }

  getInvoiceSettings(configId: string) {
    this.isContentLoading = true;
    this.apiService.getMultiInvoiceSettings(configId).subscribe({
      next: (data) => {
        this.isContentLoading = false;
        this.dataSource.data = this.sortByDomain(data);
      },
      error: (err) => {
        this.isContentLoading = false;
        this.dataSource.data = [];
        console.log(err);
      }
    })
  }

  sortByDomain(data: any) {
    return data.sort((a: any, b: any) => a.domain.toLowerCase().localeCompare(b.domain.toLowerCase()));
  }

  addSender() {
    let ref = this.dialog.open(InvoiceSenderDialogComponent, {
      width: '480px',
      data: {
        title: "spdf.app.module.invoiceSettings.addSender",
        okButtonText: "spdf.app.module.invoiceSettings.add",
        existingData : this.dataSource.data
      }
    })

    ref.afterClosed().subscribe(res => {
      if (res && res.ok) {
        this.getInvoiceSettings("ALL");
      }
    })
  }

  editSender(row: any) {
    let ref = this.dialog.open(InvoiceSenderDialogComponent, {
      width: '480px',
      data: {
        rowData: row,
        edit: true,
        title: "spdf.app.module.invoiceSettings.editSender",
        okButtonText: "spdf.app.module.buup.save",
        existingData : this.dataSource.data
      }
    })

    ref.afterClosed().subscribe(res => {
      if (res && res.ok) {
        this.getInvoiceSettings("ALL");
      }
    })
  }

  deleteSetting(rowData: any) {
    let dialogRef = this.dialog.open(DeleteSettingDialogComponent, {
      width: '480px',
      data: {
        message: 'spdf.app.module.invoiceSettings.deleteSender.message',
        title: 'spdf.app.module.invoiceSettings.deleteSender',
        okButtonText: 'spdf.app.module.invoiceSettings.delete',
        rowData: rowData
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.ok) {
        this.getInvoiceSettings("ALL");
      }
    })

  }

  goBack() {
    this.router.navigateByUrl("");
  }

  applyFilter(filterValue: any) {
    if (filterValue != null) {
      filterValue = filterValue.trim().toLowerCase();
    }

    this.dataSource.filter = filterValue;
  }

  applySort(sortOn: any) {
    const sortState: Sort = { active: sortOn.column, direction: sortOn.direction };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  clearFilterValue() {
    this.filterValue = '';
    this.applyFilter('');
  }

  tableFilter(): (sender: any, filter: string) => boolean {
    let filterFunction = function (sender: any, filter: string): boolean {
      return sender.domain.toLowerCase().includes(filter) ||
        sender.splitMethod.toLowerCase().includes(filter) ||
        sender.textToBeRecognized?.toLowerCase().includes(filter)
    }
    return filterFunction
  }
}


export const sortByItems = [
  {
    column: 'splitMethod',
    value: 'spdf.app.module.invoiceSettings.sortby.splitMethodDesc',
    direction: 'desc'
  },
  {
    column: 'splitMethod',
    value: 'spdf.app.module.invoiceSettings.sortby.splitMethodAsc',
    direction: 'asc'
  }
];
