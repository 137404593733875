import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { BaseTranslationService } from '../../app-services/base.translation.service';
import { LocalizationService } from '../../app-services/localization.service';
import { DecimalFields, InvoiceLineData, TaskResponseLineItem } from './invoice.lines.models';
import { GlobalizeService } from 'src/app/app-services/globalize.service';

@Component({
  selector: 'invoice-lines',
  templateUrl: './invoice-lines.component.html',
  styleUrls: ['./invoice-lines.component.scss']
})
export class InvoiceLinesComponent extends BaseTranslationService implements OnInit, OnChanges {

  @Input() lineItems: any[][];
  @Output() updatedLineItems = new EventEmitter<any>();
  @Output() isLineItemsValid = new EventEmitter<any>();
  @Output() readFocusOnCanvas = new EventEmitter<any>();

  isLineItemsValid$: boolean = true;
  _mappedLineItems: InvoiceLineData[] = [];
  reloadGrid: boolean = false; // toggle this variable to reload grid data

  constructor(localization: LocalizationService) {
    super(localization);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("lineItems" in changes) {
      this._mappedLineItems = [];
      this.isLineItemsValid$ = true;
      this.mapToInvoiceLineDataModel([...this.lineItems]);
      this.reloadGrid = !this.reloadGrid;
    }
  }
  ngOnInit(): void {
  }

  _readFocusOnCanvas(event: any) {
    this.readFocusOnCanvas.emit(event);
  }

  addLineItems() {
    this._mappedLineItems = [new InvoiceLineData(this._mappedLineItems.length + 1)]
  }

  updatedRowData(e: any) {
    this._mappedLineItems = e;
    this.updatedLineItems.emit(this.mapToTaskResponseLineItems(e));
  }

  mapToInvoiceLineDataModel(lineItems: any[]) {
    lineItems.forEach((lineItem: any[], index) => {
      let lineData: any = {};
      lineData["rowNumber"] = index + 1;
      lineItem.forEach(item => lineData[this.taskResponseToModelMap[item["type"]]] = { value: item["value"].toString(), data: item })
      this._mappedLineItems.push(lineData);
    })
    // console.log(this._mappedLineItems);
  }

  mapToTaskResponseLineItems(mappedLineItems: InvoiceLineData[]) {
    let _updatedLineItems: any[] = []
    mappedLineItems.forEach((lineData: any) => {
      let lineItem = this.lineItems[lineData.rowNumber - 1];
      let newLineItem: any[] = [];
      Object.keys(lineData).forEach(key => {
        if (key != 'rowNumber' && lineData[key]['value'].length > 0) {
          let itemPresent = lineItem ? lineItem.find(item => this.taskResponseToModelMap[item["type"]] == key) : undefined;
          let item = itemPresent ? itemPresent : {};
          item["type"] = this.modelToTaskResponseMap[key];
          if (DecimalFields.includes(key)) {
            item["value"] = Number(GlobalizeService.unformatAnyNumber(lineData[key]['value']));
          } else {
            item["value"] = lineData[key]['value'];
          }
          newLineItem.push(item);
        }
      })
      if (newLineItem.length > 0) {
        _updatedLineItems.push(newLineItem);
      }
    })
    return _updatedLineItems;
  }

  _isLineItemsValid(e: any) {
    this.isLineItemsValid$ = e;
    this.isLineItemsValid.emit(e);
  }

  taskResponseToModelMap: any = {
    "LINE_ITEM.ITEM.NAME": "description",
    "LINE_ITEM.LINE_EXTENSION.AMOUNT": "netTotal",
    "LINE_ITEM.ITEM.SELLERS_ITEM": "productCode",
    "LINE_ITEM.TAX_TOTAL.AMOUNT": "taxSum",
    "LINE_ITEM.PRICE.AMOUNT": "unitPrice",
    "LINE_ITEM.QUANTITY.AMOUNT": "quantity",
    "LINE_ITEM.QUANTITY.UNITCODE": "unit",
    "LINE_ITEM.ITEM.TAX_PERCENT": "taxRate",
  }

  modelToTaskResponseMap: any = {
    "description": "LINE_ITEM.ITEM.NAME",
    "netTotal": "LINE_ITEM.LINE_EXTENSION.AMOUNT",
    "productCode": "LINE_ITEM.ITEM.SELLERS_ITEM",
    "taxSum": "LINE_ITEM.TAX_TOTAL.AMOUNT",
    "unitPrice": "LINE_ITEM.PRICE.AMOUNT",
    "quantity": "LINE_ITEM.QUANTITY.AMOUNT",
    "unit": "LINE_ITEM.QUANTITY.UNITCODE",
    "taxRate": "LINE_ITEM.ITEM.TAX_PERCENT"
  }
}


