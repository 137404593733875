import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectGuard } from './app-services/redirect.guard';
import { AppConfig } from './app.config';
import { BusinessUnitUserPermissionsComponent } from './business-unit-user-permissions/business-unit-user-permissions.component';
import { BusinessUnitComponent } from './business-unit/business-unit.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { ConfigurationReportsComponent } from './configuration-reports/configuration-reports.component';
import { AdminRouteGuardGuard } from './routeGuards/admin-route-guard.guard';
import { MultiInvoiceSettingsComponent } from './multi-invoice-settings/multi-invoice-settings.component';

const routes: Routes = [
  {
    path: 'business-unit',
    component: DynamicFormComponent,
    pathMatch: 'full'
  },
  {
    path: 'userAssignments',
    component: BusinessUnitUserPermissionsComponent,
    pathMatch: 'full'
  },
  {
    path: 'configurationReports',
    component: ConfigurationReportsComponent,
    canActivate: [AdminRouteGuardGuard],
    pathMatch: 'full'
  },
  {
    path: 'invoiceSettings',
    component: MultiInvoiceSettingsComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    component: BusinessUnitComponent
  },
  {
    path: 'login',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: window.location.href.includes("ops=") ? AppConfig.BW_ACCESS_URL + '/login' + '?origin=' + AppConfig.APP_BASE_URL + '&ops=BASWARE' : AppConfig.BW_ACCESS_URL + '/login' + '?origin=' + AppConfig.APP_BASE_URL
    }
  },
  {
    path: 'logout',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: window.location.href.includes("ops=") ? AppConfig.BW_ACCESS_URL + '/logout' + '?origin=' + AppConfig.APP_BASE_URL + '&ops=BASWARE' : AppConfig.BW_ACCESS_URL + '/logout' + '?origin=' + AppConfig.APP_BASE_URL
    }
  }
];

@NgModule({

  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
