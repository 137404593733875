<div mat-dialog-title fxlayout="row">
  <div>{{tr('spdf.app.module.buup.clear.users')}}</div>
  <div class="gt-dialog-close">
    <button mat-icon-button color="primary" attr.aria-label="{{tr('spdf.app.tooltips.close')}}" matTooltip="{{tr('spdf.app.tooltips.close')}}" mat-dialog-close>
      <mat-icon fontSet="far" fontIcon="fa-times"> </mat-icon>
    </button>
  </div>
</div>

<div mat-dialog-content>
  <div class="data-field">
    {{tr('spdf.app.module.buup.clear.confirmation.line1')}} 
    <br>
    {{tr('spdf.app.module.buup.clear.confirmation.line2')}} 
  </div>
</div>
<div mat-dialog-actions>
  <!-- <mat-spinner *ngIf="creating" diameter="20"></mat-spinner> -->
  <button mat-stroked-button color="primary" mat-dialog-close attr.aria-label="{{tr('spdf.app.module.buup.cancel')}}">
    {{tr('spdf.app.module.buup.cancel')}} 
  </button>
  <button mat-flat-button color="primary" attr.aria-label="{{tr('spdf.app.module.buup.clearAll')}}" (click)="clearAll()">
    {{tr('spdf.app.module.buup.clearAll')}} 
  </button>
</div>