import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog'

import { LocalizationService } from '../app-services/localization.service';
import { BaseTranslationService } from '../app-services/base.translation.service';
export interface DialogData {
  name: string;
}


@Component({
  selector: 'app-forbidden-dialog',
  template: `
  <div mat-dialog-title>
  <div>Forbidden</div>
  <div class="gt-dialog-close">
    <button mat-icon-button color="primary" (click)="onNoClick()">
      <mat-icon fontSet="far"
                fontIcon="fa-times"
                matTooltip="Close">
      </mat-icon>
    </button>
  </div>
</div>

<div mat-dialog-content>
<label class="mat-body-1">{{tr("spdf.app.error.permissions")}}</label>
</div>
<div mat-dialog-actions>
  
  <button mat-flat-button
          color="primary" routerLink="logout">{{tr("spdf.app.error.logout")}}</button>
</div>
  `,
  styleUrls: ['./error.component.scss']
})
export class ForbiddenDialogComponent extends BaseTranslationService {

  constructor(
    public dialogRef: MatDialogRef<ForbiddenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, localization: LocalizationService
  ) {
    super(localization);
  }

  onNoClick(): void {
    this.dialogRef.close()
  }
}
