import { ElementBase } from './element-base';

export class DropdownTemplate extends ElementBase<string> {
  override controlType = 'dropdown';
  options: { key: string, value: string }[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options' as keyof typeof options] || [];
  }
}