<div [formGroup]="form">

  <div [ngSwitch]="element.controlType" id="field">

    <mat-form-field *ngSwitchCase="'string'" appearance="legacy" aria-readonly="true"
      [ngClass]="{'gt-custom-warning':errorRequiredWarning}">
      <mat-label>{{element.label}}<span *ngIf="element.required">&nbsp;*</span></mat-label>
      <input matInput (focus)="callParentReadFocus($event.target)" [id]="element.fieldCode" [type]="'text'" [ngClass]="{'decimal-field': element.fieldType=='decimal','vatId-field': element.isVatIdField,
        'gt-custom-warning-caret':errorRequiredWarning}" autocomplete="off" [attr.data-x]="element.x"
        [attr.data-y]="element.y" [attr.data-width]="element.width" [attr.data-height]="element.height"
        [attr.data-page]="element.page" (input)="onInputChange(element)" [attr.data-name]="element.fieldCode"
        [formControlName]="element.fieldCode" [(ngModel)]="element.value" (blur)="deepClear(element)"
        [minlength]="element.minLength" [maxlength]="element.maxLength">
      <button class="gt-form-field-clear" attr.aria-label="{{tr('spdf.app.tooltips.clear')}}"
        matTooltip="{{tr('spdf.app.tooltips.clear')}}" mat-icon-button matSuffix
        *ngIf="element.value && !element.readonly" (click)="callParentClearClick(element)">
        <mat-icon> cancel </mat-icon>
      </button>
      <mat-error [ngClass]="{'gt-custom-warning':errorRequiredWarning}" *ngIf="form.get(element.fieldCode)!.errors">
        <mat-icon *ngIf="errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-triangle"
          class="gt-icon"></mat-icon>
        <mat-icon *ngIf="!errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-circle"
          class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden">Warning</span>
        {{getErrorMessage(element)}}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'double'" appearance="legacy" aria-readonly="true"
      [ngClass]="{'gt-custom-warning':errorRequiredWarning}">
      <mat-label>{{element.label}}<span *ngIf="element.required">&nbsp;*</span></mat-label>
      <input matInput (focus)="callParentReadFocus($event.target)" [id]="element.fieldCode" [type]="'text'"
        [ngClass]="{'gt-custom-warning-caret':errorRequiredWarning}" autocomplete="off" [attr.data-x]="element.x"
        [attr.data-y]="element.y" [attr.data-width]="element.width" [attr.data-height]="element.height"
        [attr.data-page]="element.page" (input)="onInputChange(element)" [attr.data-name]="element.fieldCode"
        [formControlName]="element.fieldCode" [(ngModel)]="element.value" (blur)="deepClear(element)"
        [minlength]="element.minLength" [maxlength]="element.maxLength">
      <button class="gt-form-field-clear" attr.aria-label="{{tr('spdf.app.tooltips.clear')}}"
        matTooltip="{{tr('spdf.app.tooltips.clear')}}" mat-icon-button matSuffix *ngIf="element.value"
        (click)="callParentClearClick(element)">
        <mat-icon> cancel </mat-icon>
      </button>
      <mat-error [ngClass]="{'gt-custom-warning':errorRequiredWarning}" *ngIf="form.get(element.fieldCode)!.errors">
        <mat-icon *ngIf="errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-triangle"
          class="gt-icon"></mat-icon>
        <mat-icon *ngIf="!errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-circle"
          class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden">Warning</span>
        {{getErrorMessage(element)}}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'integer'" appearance="legacy" aria-readonly="true"
      [ngClass]="{'gt-custom-warning':errorRequiredWarning}">
      <mat-label>{{element.label}}<span *ngIf="element.required">&nbsp;*</span></mat-label>
      <input matInput (focus)="callParentReadFocus($event.target)" [id]="element.fieldCode" [type]="'number'"
        [ngClass]="{'gt-custom-warning-caret':errorRequiredWarning}" autocomplete="off" [attr.data-x]="element.x"
        [attr.data-y]="element.y" [attr.data-width]="element.width" [attr.data-height]="element.height"
        [attr.data-page]="element.page" (input)="onInputChange(element)" [attr.data-name]="element.fieldCode"
        [formControlName]="element.fieldCode" [(ngModel)]="element.value" (blur)="deepClear(element)"
        [minlength]="element.minLength" [maxlength]="element.maxLength">
      <button class="gt-form-field-clear" attr.aria-label="{{tr('spdf.app.tooltips.clear')}}"
        matTooltip="{{tr('spdf.app.tooltips.clear')}}" mat-icon-button matSuffix *ngIf="element.value"
        (click)="callParentClearClick(element)">
        <mat-icon> cancel </mat-icon>
      </button>
      <mat-error [ngClass]="{'gt-custom-warning':errorRequiredWarning}" *ngIf="form.get(element.fieldCode)!.errors">
        <mat-icon *ngIf="errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-triangle"
          class="gt-icon"></mat-icon>
        <mat-icon *ngIf="!errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-circle"
          class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden">Warning</span>
        {{getErrorMessage(element)}}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'date'" appearance="legacy" aria-readonly="true" #datefield class="gt-datepicker"
      floatLabel="always" [ngClass]="{'gt-custom-warning' : errorRequiredWarning}">
      <mat-label>{{element.label}}<span *ngIf="element.required">&nbsp;*</span></mat-label>
      <input autocomplete="off" matInput (focus)="callParentReadFocus($event.target)" [matDatepicker]="picker"
        [ngClass]="{'gt-custom-warning-caret':errorRequiredWarning}" [placeholder]="formatPlaceholder"
        [id]="element.fieldCode" autocomplete="off" [type]="'text'" [attr.data-x]="element.x" [attr.data-y]="element.y"
        [attr.data-width]="element.width" [attr.data-height]="element.height" [attr.data-page]="element.page"
        (input)="onInputChange(element)" (blur)="deepClear(element)" [attr.data-name]=" element.fieldCode"
        [formControlName]="element.fieldCode" />
      <button class="gt-form-field-clear" mat-icon-button matSuffix
        *ngIf="element.value || form.get(element.fieldCode)!.invalid"
        attr.aria-label="{{tr('spdf.app.tooltips.clear')}}" matTooltip="{{tr('spdf.app.tooltips.clear')}}"
        (click)="callParentClearClick(element)">
        <mat-icon> cancel </mat-icon>
      </button>
      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon fontSet="far" fontIcon="fa-calendar-alt"
          matTooltip="{{tr('spdf.app.tooltips.calendar.open')}}" aria-hidden="false">
        </mat-icon>
        <span class="cdk-visually-hidden"> {{tr('spdf.app.tooltips.calendar.open')}}</span>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error [ngClass]="{'gt-custom-warning' : errorRequiredWarning}" *ngIf="form.get(element.fieldCode)!.errors">
        <mat-icon *ngIf="errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-triangle"
          class="gt-icon"></mat-icon>
        <mat-icon *ngIf="!errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-circle"
          class="gt-icon"></mat-icon>
        {{getErrorMessage(element)}}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'dropdown'" appearance="legacy"
      [ngClass]="{'gt-custom-warning':errorRequiredWarning}">
      <mat-label>{{element.label}}<span *ngIf="element.required">&nbsp;*</span></mat-label>
      <mat-select [id]="element.fieldCode" [attr.data-x]="element.x" [attr.data-y]="element.y"
        [attr.data-width]="element.width" [attr.data-height]="element.height" [attr.data-name]="element.fieldCode"
        [formControlName]="element.fieldCode" (selectionChange)="onDocumentTypeChanged($event)">
        <mat-option *ngFor="let opt of $any(element).options" [value]="opt.key">
          {{tr(opt.value)}}
        </mat-option>
      </mat-select>
      <mat-error [ngClass]="{'gt-custom-warning':errorRequiredWarning}" *ngIf="form.get(element.fieldCode)!.errors">
        <mat-icon *ngIf="errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-triangle"
          class="gt-icon"></mat-icon>
        <mat-icon *ngIf="!errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-circle"
          class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden">Warning</span>
        {{getErrorMessage(element)}}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'aggrid'" appearance="legacy" aria-readonly="true" class="aggrid-form-field"
      [ngClass]="{'gt-custom-warning':errorRequiredWarning}">
      <mat-label>{{element.label}}<span *ngIf="element.required">&nbsp;*</span></mat-label>
      <input matInput (focus)="callParentReadFocus($event.target)" (click)="openSupplierGrid(element)"
        [id]="element.fieldCode" [type]="'text'" class="aggrid-field" autocomplete="off" [attr.data-x]="element.x"
        [attr.data-y]="element.y" [attr.data-width]="element.width" [attr.data-height]="element.height"
        [attr.data-page]="element.page" [attr.data-name]="element.fieldCode" [formControlName]="element.fieldCode"
        [(ngModel)]="element.value" [minlength]="element.minLength" [maxlength]="element.maxLength" readonly>
      <button class="gt-form-field-clear" attr.aria-label="{{tr('spdf.app.tooltips.clear')}}"
        matTooltip="{{tr('spdf.app.tooltips.clear')}}" mat-icon-button matSuffix *ngIf="element.value"
        (click)="clearGridSiblings(element)">
        <mat-icon> cancel </mat-icon>
      </button>
      <button mat-icon-button matSuffix class="my-ellipsis" [ngClass]="{'gt-custom-warning':errorRequiredWarning}"
        (click)="openSupplierGrid(element)">
        <mat-icon> more_horiz </mat-icon>
      </button>
      <mat-error [ngClass]="{'gt-custom-warning':errorRequiredWarning}" *ngIf="form.get(element.fieldCode)!.errors">
        <mat-icon *ngIf="errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-triangle"
          class="gt-icon"></mat-icon>
        <mat-icon *ngIf="!errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-circle"
          class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden">Warning</span>
        {{getErrorMessage(element)}}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'dropdown-with-lookup'" appearance="legacy" class="gt-single-select-field"
      floatLabel="always" [ngClass]="{'gt-custom-warning':errorRequiredWarning,
      'gt-custom-warning-caret':errorRequiredWarning}">
      <mat-label id="gt-mat-label">{{element.label}}<span *ngIf="element.required">&nbsp;*</span></mat-label>

      <input type="text" (focus)="callParentReadFocus($event.target)" matInput [matAutocomplete]="auto"
        [id]="element.fieldCode" [attr.data-x]="element.x" [attr.data-y]="element.y" [attr.data-width]="element.width"
        [attr.data-height]="element.height" [attr.data-page]="element.page" [attr.data-name]="element.fieldCode"
        [formControlName]="element.fieldCode" placeholder="{{tr('spdf.app.module.buup.select')}}"
        #trigger="matAutocompleteTrigger" (click)="trigger.openPanel()" aria-labelledby="gt-mat-label" />

      <button tabindex="0" class="gt-form-field-clear" mat-icon-button matSuffix *ngIf="showClearButton()"
        (click)="clearField(auto)" attr.aria-label="{{tr('spdf.app.tooltips.clear')}}"
        matTooltip="{{tr('spdf.app.tooltips.clear')}}" (click)="callParentClearClick(element)">
        <mat-icon> cancel </mat-icon>
      </button>

      <button mat-icon-button matSuffix class="gt-select-lookup-arrow" (click)="trigger.openPanel()"
        [disableRipple]="true">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-autocomplete #auto="matAutocomplete" (opened)="onPanelOpened()" (closed)="onPanelClosed();"
        (optionSelected)="onSelectionUpdate($event)" [displayWith]="displayOneFn">
        <mat-option disabled class="gt-single-select-panel-item gt-items-top-row"
          *ngIf="filteredCountriesArray!.length > 0">
          {{ filteredCountriesArray.length }} {{tr('spdf.app.module.buup.results.found')}}
        </mat-option>

        <mat-option disabled class="gt-single-select-panel-item gt-items-top-row gt-search-result-text"
          *ngIf="filteredCountriesArray!.length === 0 && formControl.value !== null">
          {{tr('spdf.app.module.buup.no.results.found')}}
        </mat-option>

        <mat-option *ngFor="let country of filteredCountries | async" [value]="country.code"
          innerHTML="{{ country.code | highlightSearch : formControl.value }}"
          [ngClass]="{'gt-selected' : country.code === selectedCountry }">
        </mat-option>
      </mat-autocomplete>

      <mat-error [ngClass]="{'gt-custom-warning':errorRequiredWarning}" *ngIf="form.get(element.fieldCode)!.errors">
        <mat-icon *ngIf="errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-triangle"
          class="gt-icon"></mat-icon>
        <mat-icon *ngIf="!errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-circle"
          class="gt-icon"></mat-icon>
        {{getErrorMessage(element)}}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'chip'" appearance="legacy" aria-readonly="true"
      [ngClass]="{'gt-custom-warning':errorRequiredWarning}">
      <mat-label>{{element.label}}<span *ngIf="element.required">&nbsp;*</span></mat-label>
      <mat-chip-list [formControlName]="element.fieldCode" [id]="element.fieldCode" #ibanChipList
        [ngClass]="{'gt-custom-warning-caret':errorRequiredWarning}" (click)="callParentReadFocus($event.target)">
        <mat-chip [selectable]="true" removable="true" (removed)="removeChip(chip, element.value);" [ngClass]="{'iban-field': element.isIbanField,
        'gt-custom-warning-caret':errorRequiredWarning}" *ngFor="let chip of element.value"
          (click)="otherChip.select();chipClicked(chip);" #otherChip="matChip" [attr.data-x]="chip.x"
          [attr.data-y]="chip.y" [attr.data-width]="chip.width" [attr.data-height]="chip.height"
          [attr.data-page]="chip.page" [attr.data-name]="element.fieldCode" class="gt-chip-responsive">{{chip.value}}
          <mat-icon matChipRemove matTooltip="{{tr('spdf.app.tooltips.remove')}}">cancel</mat-icon>
          <span class="cdk-visually-hidden">{{tr('spdf.app.tooltips.remove')}}</span>
        </mat-chip>
        <input matInput autocomplete="off" [attr.data-name]="element.fieldCode" [matChipInputFor]="ibanChipList"
          id="iban-textbox" [ngClass]="{'iban-field': element.isIbanField}" [matChipInputAddOnBlur]="true"
          [matChipInputSeparatorKeyCodes]="separatorKeys"
          (matChipInputTokenEnd)="addChip($event, element.value, element.fieldCode)"
          [placeholder]="element.value.length === 0 ? ibanPlaceholder : ''" [attr.maxlength]="element.maxLength"
          aria-label="Chip Input" />

      </mat-chip-list>
      <mat-error [ngClass]="{'gt-custom-warning':errorRequiredWarning}" *ngIf="form.get(element.fieldCode)!.errors">
        <mat-icon *ngIf="errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-triangle"
          class="gt-icon"></mat-icon>
        <mat-icon *ngIf="!errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-circle"
          class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden">Warning</span>
        {{getErrorMessage(element)}}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngSwitchCase="'bban-chip'" appearance="legacy" aria-readonly="true"
      [ngClass]="{'gt-custom-warning':errorRequiredWarning}">
      <mat-label>{{element.label}}<span *ngIf="element.required">&nbsp;*</span></mat-label>
      <mat-chip-list [formControlName]="element.fieldCode" [id]="element.fieldCode" #bbanChipList
        [ngClass]="{'gt-custom-warning-caret':errorRequiredWarning}" (click)="callParentReadFocus($event.target)">
        <mat-chip [selectable]="true" removable="true" (removed)="removeChip(chip, element.value);"
          *ngFor="let chip of element.value" (click)="otherbbanChip.select();chipClicked(chip);"
          #otherbbanChip="matChip" [attr.data-x]="chip.x" [attr.data-y]="chip.y" [attr.data-width]="chip.width"
          [attr.data-height]="chip.height" [attr.data-page]="chip.page" [attr.data-name]="element.fieldCode"
          class="bban-field gt-chip-responsive">{{chip.value}}
          <mat-icon matChipRemove matTooltip="{{tr('spdf.app.tooltips.remove')}}">cancel</mat-icon>
          <span class="cdk-visually-hidden">{{tr('spdf.app.tooltips.remove')}}</span>
        </mat-chip>
        <input matInput autocomplete="off" [attr.data-name]="element.fieldCode" [matChipInputFor]="bbanChipList"
          id="bban-textbox" class="bban-field" [matChipInputAddOnBlur]="true"
          [matChipInputSeparatorKeyCodes]="separatorKeys"
          (matChipInputTokenEnd)="addChip($event, element.value, element.fieldCode)"
          [placeholder]="element.value.length === 0 ? bbanPlaceholder : ''" aria-label="Chip Input"
          [attr.maxlength]="element.maxLength" />

      </mat-chip-list>
      <mat-error [ngClass]="{'gt-custom-warning':errorRequiredWarning}" *ngIf="form.get(element.fieldCode)!.errors">
        <mat-icon *ngIf="errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-triangle"
          class="gt-icon"></mat-icon>
        <mat-icon *ngIf="!errorRequiredWarning" fontSet="fas" fontIcon="fa-exclamation-circle"
          class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden">Warning</span>
        {{getErrorMessage(element)}}
      </mat-error>
    </mat-form-field>
  </div>

</div>