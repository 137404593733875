import { Pipe, PipeTransform } from "@angular/core";
// Pipe transform for highlighting matching bits of strings in
// the search results.
@Pipe({
    name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
    transform(value: string, search: string): string {
        // Wrap the entire string in a span to prevent omission of spaces
        let valueStr = '<span class="gt-search-result">' + value + '</span>';
        return valueStr.replace(
            new RegExp(
                '(?![^&;]+;)(?!<[^<>]*)(' + search + ')(?![^<>]*>)(?![^&;]+;)',
                'gi'
            ),
            '<span class="gt-search-highlight">$1</span>'
        );
    }
}