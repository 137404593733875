import { Injectable } from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { BehaviorSubject } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { ForbiddenDialogComponent } from '../error/error.component';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    hidePanel = true;
    loadApplicationGoups = true;
    loadUsers = true;
    loadUsersInProgress = false;
    queryString = "";
    vendorUrl: string[];
    baswareCustomerId: string = '';
    userLocale: string = '';
    invoiceLocale: string = '';
    admin = false;
    user: string = "";
    cmosEnv: string;

    setVendorUrl(url: string[]) {
        this.vendorUrl = url;
    }

    showForbiddenDialog(): void {
        const dialogRef = this.dialog.open(ForbiddenDialogComponent, {
            width: '480px'
        });

        dialogRef.beforeClosed().subscribe(updatedName => {
        });
    }

    getVendorUrl() {
        return this.vendorUrl;
    }
    constructor(public mediaObserver: MediaObserver, public dialog: MatDialog) {
        this.mediaObserver.asObservable().subscribe((change: MediaChange[]) => {
            let flexValue = flexValues.get(change[0].mqAlias);
            this.flexValue.next(flexValue ? flexValue : 100)
        })
    }

    public flexValue = new BehaviorSubject<number>(25);

    setBaswareCustomerId(id: string) {
        this.baswareCustomerId = id;
    }

    getBaswareCustomerId() {
        return this.baswareCustomerId;
    }

    setUserLocale(locale: string) {
        this.userLocale = locale;
    }

    getUserLocale() {
        return this.userLocale;
    }

    setInvoiceLocale(locale: string) {
        this.invoiceLocale = locale;
    }

    getInvoiceLocale() {
        return this.invoiceLocale;
    }

    setAdmin(admin: boolean) {
        this.admin = admin;
    }

    isAdmin() {
        return this.admin;
    }

    setCmosEnv(val: string){
        this.cmosEnv = val;
    }

    getCmosEnv(){
        return this.cmosEnv;
    }

}

export const flexValues = new Map<string, number>([
    ['xs', 100],
    ['sm', 50],
    ['md', 33],
    ['lg', 25],
    ['xl', 16.6],
    ['gt-xl', 16.6]
]);