<div mat-dialog-title fxlayout="row">
    <div>{{tr('spdf.app.dialog.session.title')}}</div>
</div>

<div mat-dialog-content>
    <div class="data-field">
        <strong>{{tr(data.messageKey1)}}: </strong>{{minsLeft}} mins {{secsLeft}} seconds
    </div>
    <div class="data-field">
        {{tr(data.messageKey2)}}
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close (click)="logout()">
        {{tr('spdf.app.actions.logout.now')}}
    </button>
    <button mat-flat-button color="primary" (click)="extend()">
        {{tr('spdf.app.actions.extendsession')}}
    </button>
</div>