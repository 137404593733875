import { MatPaginatorIntl } from '@angular/material/paginator';
import { BaseTranslationService } from '../app-services/base.translation.service';
import { LocalizationService } from './localization.service';

export class CustomPaginatorConfig extends MatPaginatorIntl {
    override itemsPerPageLabel = '';
    override nextPageLabel = '';
    override previousPageLabel = '';

    constructor(localizationService: LocalizationService, private baseTranslation: BaseTranslationService) {
        super();
        this.itemsPerPageLabel = baseTranslation.tr("spdf.app.pagination.labels.itemsperpage").toString();
        this.nextPageLabel = baseTranslation.tr("spdf.app.pagination.labels.nextpage").toString();
        this.previousPageLabel = baseTranslation.tr("spdf.app.pagination.labels.previouspage").toString();
        this.getRangeLabel = this.fetchRangeLabel();
    }

    fetchRangeLabel() {
        return (page: number, pageSize: number, length: number) => {
            if (length == 0 || pageSize == 0) { return `0 / ${length}`; }

            length = Math.max(length, 0);

            const startIndex = page * pageSize;

            // If the start index exceeds the list length, do not try and fix the end index to the end.
            const endIndex = startIndex < length ?
                Math.min(startIndex + pageSize, length) :
                startIndex + pageSize;

            return `${startIndex + 1} - ${endIndex} / ${length}`;
        }
    };
}