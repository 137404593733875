
export class supplierInfo {
  companyCode: number;
  supplierCode: number | string;
  supplierName: string;
  supplierStreet: string;
  supplierCity: string;
  supplierPostalCode: number;
  phone: number;
  vat: string;
  einvoiceAddress: number;
  bankAccounts: [
    {
      number: string,
      iban: string
    },
    {
      number: string,
      iban: string
    }
  ]
  address?: string;
}