import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { LocalizationService } from 'src/app/app-services/localization.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent extends BaseTranslationService implements OnInit {

  listOfUsers: any[];
  selectedUser: any = '';

  okButtonText = "spdf.app.module.buup.ok"
  title = "spdf.app.module.buup.user"
  label = "spdf.app.module.buup.users"

  constructor(private dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, localization: LocalizationService) {
    super(localization);
    if (data) {
      this.title = data.title;
      this.okButtonText = data.okButtonText;
      this.label = data.label;
      this.listOfUsers = this.data.listOfUsers;
    }
  }

  ngOnInit(): void { }

  replaceUsers() {
    this.dialogRef.close({
      ok: true,
      selectedUser: this.selectedUser
    });
  }

  removeSelected(ev: Event, option:any) {
    ev.stopPropagation();
    ev.preventDefault();
    // filter out the deleted option
    this.selectedUser= '';
  }
}
