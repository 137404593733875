<div mat-dialog-title>
    <div>{{tr(title)}}</div>
    <div class="gt-dialog-close">
        <button mat-icon-button color="primary" aria-label="{{tr('spdf.app.tooltips.close')}}"
            matTooltip="{{tr('spdf.app.tooltips.close')}}" mat-dialog-close>
            <mat-icon fontSet="far" fontIcon="fa-times"> </mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content>
    <p>
        {{tr(message)}}
    </p>
</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close attr.aria-label="{{tr('spdf.app.module.buup.cancel')}}">
        {{tr(cancelButtonText)}}
    </button>
    <button mat-flat-button color="primary" attr.aria-label="{{tr('spdf.app.module.buup.save')}}" (click)="ok()">
        {{tr(confirmButtonText)}}
    </button>
</div>