//This service sets-up top navigation bar routes in application

import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { SubSink } from "subsink";
import { fromEvent, Observable } from "rxjs";
import { gearMenuRoutes, logoRoute, userMenuRoutes } from '../models/navbarData';
import { AppConfig } from "../app.config";
import { LocalizationService } from "./localization.service";
import { BaseTranslationService } from "./base.translation.service";
import { CommonService } from "./common.service";
import { Location } from "@angular/common";
// import { Store } from "@ngrx/store";
// import { AppState } from "../state/app.state";
// import { getEmailOfUser, getNameOfUser } from "src/app/modules/home/state/home.selector";

@Injectable({
    providedIn: 'root'
})
export class NavigationRoutesService extends BaseTranslationService implements OnDestroy {
    private subs = new SubSink();
    private navigation: any = null;
    nameOfUser: string;
    emailOfUser: string;

    constructor(private router: Router, localization: LocalizationService, private commonService: CommonService,
        private route: Location
        // ,private store: Store<AppState>
    ) {
        super(localization);
        // this.nameOfUser$ = this.store.select(nameOfUser);
        // this.emailOfUser$ = this.store.select(getEmailOfUser);
    }

    //initialize navigation bar and set routes
    setUpNavigationBar(appRoute: any) {
        this.navigation = document.querySelector("alusta-navigation");
        this.navigation.navigationRoutes = appRoute;
        userMenuRoutes.forEach(route => {
            route.title = this.tr(route.title).toString();
            route.tooltip = this.tr(route.tooltip).toString();
        });
        gearMenuRoutes.forEach(route => {
            route.title = this.tr(route.title).toString();
            route.tooltip = this.tr(route.tooltip).toString();
        });
        let enabledGearMenuRoutes = gearMenuRoutes.filter(route => route.enableFeature)
        this.navigation.userMenuRoutes = userMenuRoutes;
        this.navigation.gearMenuRoutes = this.commonService.isAdmin() ? enabledGearMenuRoutes : [];
        this.navigation.logoRoute = logoRoute;
        this.navigation.addEventListener("userMenuRouteClicked", (e: any) => {
            this.router.navigate([e.detail.path]);
        });
        this.navigation.addEventListener("gearMenuRouteClicked", (e: any) => {
            this.router.navigate([e.detail.path]);
        });
        this.navigation.addEventListener("logoRouteClicked", (e: any) => {
            this.setActiveRoute(e.detail.path.replaceAll('/', ''));
            this.router.navigate([e.detail.path]);
        });
        this.subs.add(
            fromEvent(window, 'routeClicked').subscribe((e) => {
                this.navigationRouteClicked(e);
            })
        )
    }

    navigationRouteClicked(event: any): void {
        this.navigation.activeRouteId = event.detail.id
        this.router.navigate(event.detail.path.split("/"));
    }

    //update username
    updateUserNavigationData() {
        // let nameOfUser = "";
        // this.nameOfUser$.subscribe(res => nameOfUser = res);

        if (AppConfig.NAME_OF_THE_USER) {
            this.navigation.setAttribute("user-name", AppConfig.NAME_OF_THE_USER);
        } else {
            this.navigation.setAttribute("user-name", '');
        }
        // else {
        //     let emailOfUser = "";
        //     // this.emailOfUser$.subscribe(res => emailOfUser = res);
        //     this.navigation.setAttribute("user-name", emailOfUser);
        // }
    }

    //Set active route
    setActiveRoute(id: string): void {
        this.navigation.activeRouteId = id
    }

    //hide nav bar
    hideNavigationBar() {
        this.navigation = document.querySelector("alusta-navigation");
        this.navigation.style.display = 'none';
    }

    // Unsubscribe when the service dies
    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //logout user
    logout() {
        sessionStorage.clear();
        this.router.navigate(['logout']);
    }

    setCurrentRoute(): void {
        let routeId: string = "smartPdfHome";
        let path = this.route.path();
        if (path.indexOf('?') > -1) {
            let queryString = this.route.path().substring(1);
            this.commonService.queryString = queryString;
        }
        if (path.includes("users")) {
            routeId = "sub_usermanagement";
        } else if (path.includes("userAssignments")) {
            routeId = "userAssignments";
        } else if (path.includes("configurationReports")) {
            routeId = "configurationReports";
        } else if (path == "") {
            routeId = "smartPdfHome";
        }
        this.setActiveRoute(routeId);
    }
}