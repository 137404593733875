export const appRoutes = [
    { path: '', title: "SmartPDF", id: "smartPdfHome", children: [] },
    // { path: "/business-unit", title: "Document validation", id: "business-unit" }
]

export const baswareApplications = ["PurchaseToPay", "Reporting", "SupplierPortal", "SupplierManagement",
    "CloudscanWithSelfValidation", "CloudscanWithBaswareValidation", "BaswareAdmin", "SmartPDF"]

export const receivingEmailDomains = ["pdf.basware.com", "bscs.basware.com", "testpdf.basware.com", "bscstest.basware.com",
    "siepdf.basware.com", "baswaretest.com", "devpdf.basware.com", "devbscs.basware.com"]

export const receivingEmailaddressDomains = ["@pdf.basware.com", "@bscs.basware.com", "@testpdf.basware.com", "@bscstest.basware.com",
    "@siepdf.basware.com", "@baswaretest.com", "@devpdf.basware.com", "@devbscs.basware.com"]
