<div mat-dialog-title fxlayout="row">
    <div>{{tr('spdf.app.dialog.email.title')}}</div>
</div>

<div mat-dialog-content>
    <mat-form-field appearance="legacy" aria-readonly="true">
        <mat-label>{{tr('spdf.app.dialog.email.sent.by')}}</mat-label>
        <input matInput value="{{data.emailData.fromSender}}" readonly disabled
      /></mat-form-field>
      <mat-form-field appearance="legacy" aria-readonly="true">
        <mat-label>{{tr('spdf.app.dialog.email.subject')}}</mat-label>
        <input matInput value="{{data.emailData.subject}}" readonly disabled
      /></mat-form-field>
      <mat-form-field appearance="legacy" aria-readonly="true">
        <mat-label>{{tr('spdf.app.dialog.email.date')}}</mat-label>
        <input matInput value="{{data.emailData.timeEmailReceived}}" readonly disabled
      /></mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-flat-button color="primary" mat-dialog-close>
        {{tr('spdf.app.actions.close')}}
    </button>
</div>
