import { Component, OnInit, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { ApiService } from '../app-services/api.service';
import { BaseTranslationService } from '../app-services/base.translation.service';
import { CommonService } from '../app-services/common.service';
import { LocalizationService } from '../app-services/localization.service';
import { AppConfig } from '../app.config';
import { BusinessUnit, BusinessUnitUserMap } from '../models/businessUnit';
import { ClearAllUsersComponent } from './dialogs/clear-all-users/clear-all-users.component';
import { EditUserComponent } from './dialogs/edit-user/edit-user.component';
import { UnsavedDataWarningComponent } from './dialogs/unsaved-data-warning/unsaved-data-warning.component';
import { catchError, forkJoin, map, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-business-unit-user-permissions',
  templateUrl: './business-unit-user-permissions.component.html',
  styleUrls: ['./business-unit-user-permissions.component.scss']
})
export class BusinessUnitUserPermissionsComponent extends BaseTranslationService implements OnInit, AfterViewInit {

  subs = new SubSink();
  flexValue: number;

  constructor(public router: Router, public common: CommonService, public dialog: MatDialog, public apiService: ApiService, localization: LocalizationService) {
    super(localization);
    this.subs.add(this.common.flexValue.subscribe(
      value => { this.flexValue = value }
    ))
  }

  disableUserModifications = true;
  disableEditUnit = false;
  disableUnitSelection = false;
  updatingUnits = false;
  showErrorBanner = false;
  savingUnitChanges = false;

  selectedUnits: BusinessUnit[] = [];
  allSelected = false;
  someSelected = false;

  filterValue: string = '';
  expandedElement: any;
  columnsToDisplay = ['select', 'businessUnit', 'eInvoicingAddress', 'invoicingEmailAddress', 'assignedTo', 'actions'];
  dataSource = new MatTableDataSource<BusinessUnit>();
  sortByItems = sortByItems;
  listOfUsers: any[];

  isContentLoading: boolean = true;

  progressBarValue = AppConfig.PROGRESS_BAR_VALUE;
  progressBarBufferValue = AppConfig.PROGRESS_BAR_BUFFER_VALUE;

  sortValue: any = this.sortByItems[0];

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.applySort(this.sortValue);
  }

  async ngOnInit() {
    this.dataSource.sortData = sortData();
    this.dataSource.filterPredicate = this.tableFilter();
    this.getAllUsers();
    await this.getBusinessUnitsAndUsers();
  }

  getAllUsers() {
    this.apiService.GetAllUsers().subscribe((data: any) => {
      this.listOfUsers = this.sortUserList(data);
    });
  }

  sortUserList(users: any[]) {
    if (!users) {
      return [];
    }
    users.sort((a: any, b: any) => {
      a = a.name + ' (' + a.email + ')';
      b = b.name + ' (' + b.email + ')';
      return a.toLowerCase().localeCompare(b.toLowerCase())
    });
    return users;
  }

  assignUsers(units: BusinessUnit[], businessUnitUsersMap: any) {
    units.forEach((unit: BusinessUnit) => {
      let map = businessUnitUsersMap.permissions.filter((unitUser: BusinessUnitUserMap) => unitUser.unitUuid == unit.unitUuid);
      let users = map.length > 0 ? map[0].users : [];
      unit.users = users;
    })
  }

  async getBusinessUnitsAndUsers() {
    Promise.all([this.apiService.GetBusinessUnits(), this.apiService.GetBusinessUnitUsers()]).then(res => {
      let businessUnits: any = res[0];
      let businessUnitUsersMap: any = res[1];

      if (businessUnitUsersMap.permissions) {
        this.assignUsers(businessUnits.queuesData, businessUnitUsersMap)
      } else {
        businessUnits.queuesData.forEach((unit: BusinessUnit) => unit.users = [])
      }
      this.dataSource.data = businessUnits.queuesData;
      this.isContentLoading = false;
    }).catch(error => {
      //handle
      console.log("ERROR");
      this.dataSource.data = [];
      this.isContentLoading = false;
    })
  }

  applyFilter(filterValue: any) {
    if (filterValue != null) {
      filterValue = filterValue.trim().toLowerCase();
    }

    this.dataSource.filter = filterValue;
  }

  applySort(sortOn: any) {
    const sortState: Sort = { active: sortOn.column, direction: sortOn.direction };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  unitSelectionChanged(element: BusinessUnit, checked: boolean) {
    if (checked) {
      this.selectedUnits.push(element);
      element.selected = true;
    } else {
      this.selectedUnits = this.selectedUnits.filter(unit => unit.businessUnit != element.businessUnit);
      element.selected = false;
    }

    this.allSelected = this.selectedUnits.length == this.dataSource.data.length;
    this.someSelected = this.selectedUnits.length > 0 && !this.allSelected;
    this.updateEditOptions();
  }

  setAll(checked: boolean) {
    if (checked) {
      this.selectedUnits = this.dataSource.data;
      this.selectedUnits.forEach(unit => unit.selected = true);
      this.allSelected = true;
    } else {
      this.selectedUnits.forEach(unit => unit.selected = false);
      this.selectedUnits = [];
      this.allSelected = false;
    }
    this.updateEditOptions();
  }

  updateEditOptions() {
    if (this.selectedUnits.length > 0) {
      this.disableUserModifications = false;
      this.disableEditUnit = true;
    } else {
      this.disableUserModifications = true;
      this.disableEditUnit = false;
    }
  }

  editUnit(event: any, unit: BusinessUnit) {
    event.stopPropagation();
    this.expandedElement = this.expandedElement === unit ? null : unit;
    this.disableEditUnit = true;
    this.disableUnitSelection = true;
  }

  cancelEditingUnit(unit: any) {
    this.disableEditUnit = false;
    this.disableUnitSelection = false;
    this.expandedElement = null;
  }

  saveUnitChanges(event: any, unit: BusinessUnit) {
    let body = {
      permissions: [{
        unitUuid: unit.unitUuid,
        users: event.selectedUsers
      }]
    };
    this.showErrorBanner = false;
    this.savingUnitChanges = true;
    this.apiService.putBusinessUnitUsers(body).subscribe(data => {
      unit.users = event.selectedUsers;
      this.disableEditUnit = false;
      this.savingUnitChanges = false;
      this.disableUnitSelection = false;
      this.expandedElement = null;
    }, error => {
      this.savingUnitChanges = false;
      this.showErrorBanner = true;
    })
  }

  openAddUserDialog() {
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '480px',
      data: {
        listOfUsers: this.sortUserList(this.listOfUsers),
        title: "Add User",
        label: "User",
        okButtonText: "Add"
      }
    })

    dialogRef.afterClosed().subscribe(results => {
      if (results.ok === true) {
        let body: any = {
          permissions: []
        }
        this.selectedUnits.forEach(unit => {
          if (!this.checkIfUserAlreadyAssigned(unit, results.selectedUser)) {
            body.permissions.push({ unitUuid: unit.unitUuid, users: unit.users?.concat(results.selectedUser) })
          }
        });

        this.updateBusinessUnitUsers({ ...body });
      }
    })
  }

  openRemoveUserDialog() {
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '480px',
      data: {
        listOfUsers: this.sortUserList(this.getAssignedUsers()),
        title: "Remove User",
        label: "User",
        okButtonText: "Remove"
      }
    });

    dialogRef.afterClosed().subscribe(results => {
      if (results.ok === true) {
        let body: any = {
          permissions: []
        }
        this.selectedUnits.forEach(unit => {
          if (this.checkIfUserAlreadyAssigned(unit, results.selectedUser)) {
            body.permissions.push({ unitUuid: unit.unitUuid, users: unit.users?.filter(u => u.email != results.selectedUser.email) })
          }
        });

        this.updateBusinessUnitUsers({ ...body });
      }
    })
  }

  openReplaceUserDialog() {
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '480px',
      data: {
        listOfUsers: this.listOfUsers,
        title: "Replace Users",
        label: "New user",
        okButtonText: "Replace"
      }
    })

    dialogRef.afterClosed().subscribe(results => {
      if (results.ok === true) {
        let body: any = {
          permissions: []
        }
        this.selectedUnits.forEach(unit => {
          body.permissions.push({ unitUuid: unit.unitUuid, users: [results.selectedUser] });
        });

        this.updateBusinessUnitUsers({ ...body });
      }
    })
  }

  openClearAllUserDialog() {
    const dialogRef = this.dialog.open(ClearAllUsersComponent, {
      width: '480px'
    })

    dialogRef.afterClosed().subscribe(results => {
      if (results.ok === true) {
        let body: any = {
          permissions: []
        }
        this.selectedUnits.forEach(unit => {
          body.permissions.push({ unitUuid: unit.unitUuid, users: [] });
        });

        this.updateBusinessUnitUsers({ ...body });
      }
    })
  }

  checkIfUserAlreadyAssigned(unit: BusinessUnit, user: any) {
    let index = unit.users?.findIndex(u => u.email == user.email);
    return index != -1;
  }

  checkIfUserExists(users: any[], user: any) {
    let index = users?.findIndex(u => u.email == user.email);
    return index != -1;
  }

  updateBusinessUnitUsers(body: any) {
    if (body.permissions.length == 0) {
      return;
    }
    let apiCalls: any[] = []
    this.updatingUnits = true;
    this.showErrorBanner = false;
    apiCalls.push(this.putBusinessUnitUsers({ permissions: body.permissions }));
    forkJoin(apiCalls).subscribe((res: any[]) => {
      this.updatingUnits = false;
      if (res.findIndex(x => !x.success) >= 0) {
        this.showErrorBanner = true;
      } else {
        this.updateUsersAndFlags();
      }
    })
  }

  putBusinessUnitUsers(batch: any) {
    return this.apiService.putBusinessUnitUsers(batch).pipe(map(res => {
      return ({ success: true, data: res });
    }), catchError((error: HttpErrorResponse) => {
      return of({ error: error, success: false, data: null });
    }))
  }

  getAssignedUsers() {
    let users: any[] = [];
    this.selectedUnits.forEach(unit => {
      unit.users?.forEach(user => {
        if (!this.checkIfUserExists(users, user)) {
          users.push(user);
        }
      })
    })
    return users;
  }

  updateUsersAndFlags() {
    this.isContentLoading = true;
    this.apiService.GetBusinessUnitUsers().then(res => {
      let businessUnitUsers: any = res;
      this.assignUsers(this.selectedUnits, businessUnitUsers)
      this.isContentLoading = false;
      this.resetFlags();
    })
  }

  resetFlags() {
    this.selectedUnits.forEach(unit => unit.selected = false);
    this.selectedUnits = [];
    this.disableUserModifications = true;
    this.disableEditUnit = false;
    this.allSelected = false;
    this.someSelected = false;
  }

  getUserNames(users: any[]) {
    return users.map((user: any) => user.name);
  }


  clearFilterValue() {
    this.filterValue = '';
    this.applyFilter('');
  }

  goBack() {
    if (this.expandedElement != null) {
      let dialogRef = this.dialog.open(UnsavedDataWarningComponent, {
        width: '480px',
        data: {
          messageKey: 'spdf.app.module.buup.unsavedData.message'
        }
      });

      dialogRef.afterClosed().subscribe(results => {
        if (results.ok === true) {
          if (results.action == 'discard') {
            this.router.navigateByUrl("");
          }
          if (results.action == 'goBack') {

          }
        }
      })
    } else {
      this.router.navigateByUrl("");
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    console.log('back button pressed');
    // window.alert('hgf');
    // window.history.forward();
    // this.goBack();
  }


  tableFilter(): (unit: BusinessUnit, filter: string) => boolean {
    let filterFunction = function (unit: BusinessUnit, filter: string): boolean {
      return unit.businessUnit.toLowerCase().includes(filter) ||
        unit.invoiceAddress.toLowerCase().includes(filter) ||
        unit.invoiceEmail.toLowerCase().includes(filter) ||
        (unit.users ? unit.users.map(u => u.name).join(', ').toLowerCase().includes(filter) : false)
    }
    return filterFunction
  }


}

export function sortData() {
  let sortFunction =
    (items: BusinessUnit[], sort: MatSort): BusinessUnit[] => {
      if (!sort.active || sort.direction === '') {
        return items;
      }
      return items.sort((a: BusinessUnit, b: BusinessUnit) => {
        let comparatorResult = 0;
        switch (sort.active) {
          case 'businessUnit':
            comparatorResult = a.businessUnit.toLowerCase().localeCompare(b.businessUnit.toLowerCase());
            break;
          case 'eInvoicingAddress':
            comparatorResult = a.invoiceAddress.toLowerCase().localeCompare(b.invoiceAddress.toLowerCase());
            break;
          case 'invoicingEmailAddress':
            comparatorResult = a.invoiceEmail.toLowerCase().localeCompare(b.invoiceEmail.toLowerCase());
            break;
          default:
            comparatorResult = a.businessUnit.toLowerCase().localeCompare(b.businessUnit.toLowerCase());
            break;
        }
        return comparatorResult * (sort.direction == 'asc' ? 1 : -1);
      });
    };
  return sortFunction;
}

export const sortByItems = [
  {
    column: 'businessUnit',
    value: 'spdf.app.module.bu.sortBy.keys.businessUnitAsc',
    direction: 'asc'
  },
  {
    column: 'businessUnit',
    value: 'spdf.app.module.bu.sortBy.keys.businessUnitDesc',
    direction: 'desc'
  },
  {
    column: 'eInvoicingAddress',
    value: 'spdf.app.module.bu.sortBy.keys.eInvoicingAddressAsc',
    direction: 'asc'
  },
  {
    column: 'eInvoicingAddress',
    value: 'spdf.app.module.bu.sortBy.keys.eInvoicingAddressDesc',
    direction: 'desc'
  },
  {
    column: 'invoicingEmailAddress',
    value: 'spdf.app.module.bu.sortBy.keys.invoiceEmailAsc',
    direction: 'asc'
  },
  {
    column: 'invoicingEmailAddress',
    value: 'spdf.app.module.bu.sortBy.keys.invoicesEmailDesc',
    direction: 'desc'
  }
];

