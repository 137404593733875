import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class AppConfig {

    constructor() {
    }

    public static NAME_OF_THE_USER = '';
    public static BROWSER_CULTURE = navigator.language.split('-')[0];
    public static USER_CULTURE = '';
    public static INVOICE_CULTURE = '';
    public static APP_CONTEXT_ROOT = '';
    public static APP_BASE_API_URL = environment.app_base_api_url + AppConfig.APP_CONTEXT_ROOT;
    public static SMARTPDF_BASE_API_URL = (custId: string) => AppConfig.APP_BASE_API_URL + `/spdf-cmos-app/${custId}`;
    public static APP_BASE_URL = environment.app_base_url;
    public static BW_ACCESS_URL = environment.bw_access_url;
    public static SESSION_WARNING_STARTS_TIME_IN_MINS = 5;
    public static RESET_SESSION_TIME_IN_MINS = 30;
    // allow a string that starts with white spaces and will not allow a string containing only white spaces
    public static NO_WHITESPACE_REGEX = /^(\s+\S+\s*)*(?!\s).*$/;


    public static DATE_FORMATS = {
        DDMMYYYY: 'DD.MM.YYYY',
        YYYYMMDD: 'YYYY-MM-DD',
    };

    //canvas image viewer configuration
    public static FILL_STYLE_HOVER = '#0000000A';
    public static STROKE_STYLE_HOVER = '#000000DE';
    public static FILL_STYLE_CLICK = '#00A9CE3D';
    public static STROKE_STYLE_CLICK = '#00A9CE';
    public static RECT_PADDING = 4;
    public static STROKE_WIDTH = 6;
    public static IMAGE_START_SCALE = 1;
    public static IMAGE_MAX_SCALE = 1.9;
    public static IMAGE_MIN_SCALE = 0.1;
    public static HOVER_SELECTION_DELAY_IN_MILLIS = 150;
    public static PROGRESS_BAR_VALUE = 50;
    public static PROGRESS_BAR_BUFFER_VALUE = 75;
    public static SNACKBAR_DURATION_IN_SECONDS = 4;
    public static IMAGE_NAVIGATION_VALUES = {
        NEXT: 'n',
        PREVIOUS: 'p',
        FIRST: 'f',
        LAST: 'l'
    };
    public static MATERIAL_PAGINATOR_PAGE_SIZE_OPTIONS = [5, 10, 20, 100];
    public static DEFAULT_PAGE_SIZE = 20;

    //mapped w.r.t keys of sort parameters
    public static BU_SORT_PARAMS_KEYMAP = {
        BUSINESS_UNIT_ASC: 'businessUnit-ASC',
        BUSINESS_UNIT_DESC: 'businessUnit-DESC',
        E_INVOICING_ADDRESS_ASC: 'invoiceAddress-ASC',
        E_INVOICING_ADDRESS_DESC: 'invoiceAddress-DESC',
        INVOICES_IN_QUEUE_ASC: 'messageCount-ASC',
        INVOICES_IN_QUEUE_DESC: 'messageCount-DESC'
    };

    public static REPORT_ONCE_MONTHS_LIMIT = 36;

    public static SORT_PARAMS = {
        [AppConfig.BU_SORT_PARAMS_KEYMAP.BUSINESS_UNIT_ASC]: 'spdf.app.module.bu.sortBy.keys.businessUnitAsc',
        [AppConfig.BU_SORT_PARAMS_KEYMAP.BUSINESS_UNIT_DESC]: 'spdf.app.module.bu.sortBy.keys.businessUnitDesc',
        [AppConfig.BU_SORT_PARAMS_KEYMAP.E_INVOICING_ADDRESS_ASC]: 'spdf.app.module.bu.sortBy.keys.eInvoicingAddressAsc',
        [AppConfig.BU_SORT_PARAMS_KEYMAP.E_INVOICING_ADDRESS_DESC]: 'spdf.app.module.bu.sortBy.keys.eInvoicingAddressDesc',
        [AppConfig.BU_SORT_PARAMS_KEYMAP.INVOICES_IN_QUEUE_ASC]: 'spdf.app.module.bu.sortBy.keys.invoicesInQueueAsc',
        [AppConfig.BU_SORT_PARAMS_KEYMAP.INVOICES_IN_QUEUE_DESC]: 'spdf.app.module.bu.sortBy.keys.invoicesInQueueDesc'
    };

    public static DOCUMENT_TYPE_LIST = [
        { key: '380', value: 'spdf.app.module.docType.invoice' },
        { key: '381', value: 'spdf.app.module.docType.creditNote' },
        { key: '10004', value: 'spdf.app.module.docType.nonInvoice' }
    ];

    //hidden field codes in UI
    public static HIDDEN_FIELDS = [
        'SUPPLIER_PARTY_NAME',
        'COMPANY_EINVOICE_ADDRESS',
        'COMPANY_NUMBER',
        'COMPANY_NAME',
    ];

    public static ADDRESS_HIDDEN_FIELDS = [
        'SUPPLIER_PARTY_POSTAL_ADDRESS_POSTAL_ZONE',
        'SUPPLIER_PARTY_POSTAL_ADDRESS_CITY_NAME',
        'SUPPLIER_PARTY_POSTAL_ADDRESS_LINES',
        'SUPPLIER_PARTY_POSTAL_ADDRESS_POSTAL_ZONE,SUPPLIER_PARTY_POSTAL_ADDRESS_CITY_NAME,SUPPLIER_PARTY_POSTAL_ADDRESS_LINES',
        'CUSTOMER_PARTY_POSTAL_ADDRESS_POSTAL_ZONE,CUSTOMER_PARTY_POSTAL_ADDRESS_CITY_NAME,CUSTOMER_PARTY_POSTAL_ADDRESS_LINES,CUSTOMER_PARTY_POSTAL_ADDRESS_COUNTRY,CUSTOMER_PARTY_POSTAL_ADDRESS_STATE',
        'CUSTOMER_PARTY_POSTAL_ADDRESS_POSTAL_ZONE',
        'CUSTOMER_PARTY_POSTAL_ADDRESS_CITY_NAME',
        'CUSTOMER_PARTY_POSTAL_ADDRESS_LINES',
        'CUSTOMER_PARTY_POSTAL_ADDRESS_COUNTRY',
        'CUSTOMER_PARTY_POSTAL_ADDRESS_STATE',
        'CUSTOMER_PARTY_SHIPTO_ADDRESS_NAME,CUSTOMER_PARTY_SHIPTO_ADDRESS_POSTAL_ZONE,CUSTOMER_PARTY_SHIPTO_ADDRESS_CITY_NAME,CUSTOMER_PARTY_SHIPTO_ADDRESS_LINES,CUSTOMER_PARTY_SHIPTO_ADDRESS_COUNTRY,CUSTOMER_PARTY_SHIPTO_ADDRESS_STATE',
        'CUSTOMER_PARTY_SHIPTO_ADDRESS_NAME',
        'CUSTOMER_PARTY_SHIPTO_ADDRESS_POSTAL_ZONE',
        'CUSTOMER_PARTY_SHIPTO_ADDRESS_CITY_NAME',
        'CUSTOMER_PARTY_SHIPTO_ADDRESS_LINES',
        'CUSTOMER_PARTY_SHIPTO_ADDRESS_COUNTRY',
        'CUSTOMER_PARTY_SHIPTO_ADDRESS_STATE'
    ];

    //API config
    public static INITIALIZE = '/initialize';
    public static GET_BUSINESS_UNITS = '/units/queues';
    public static GET_INVOICE_SETTINGS_URL = (baswareCustomerId: string, configId: string) => `/invoiceSettings/${baswareCustomerId}/${configId}`;
    public static POST_INVOICE_SETTINGS_URL = `/invoiceSettings`;
    public static GET_TASK = '/task/queue';
    public static GET_OCR_DATA = '/ocr';
    public static SKIP_TASK = '/task/skip';
    public static PROCESS_TASK = '/task/process';
    public static BUSINESS_UNIT_USERS = '/unitusers/customers';
    public static GET_ALL_USERS = '/users/customers';
    public static EXTEND_SESSION = '/initialize/extendsession';
    public static EMAIL_DETAILS = (bumId: string) => `/email/details/${bumId}`;
    public static CONFIG_REPORTS = '/configurationReports';
    public static APPROVE_CONFIG = (configurationId: string) => `/configurations/${configurationId}/approve`;
    public static REJECT_CONFIG = (configurationId: string) => `/configurations/${configurationId}/reject`;
    public static DOWNLOAD_CONFIG_REPORT = (configurationId: string, snapshotuuid: string) => `/configurations/${configurationId}/snapshot/${snapshotuuid}`;

}