import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { GlobalizeService } from '../app-services/globalize.service';
import { AppConfig } from '../app.config';
import { DecimalValidator } from '../validators/decimal.validator';
import { requiredWarningValidator } from '../validators/requiredWarning.validator';
import { ElementBase } from './element-base';

@Injectable()
export class ElementControlService {
  constructor() { }

  toFormGroup(fields: ElementBase<any>[]) {
    let group: any = {};
    fields.forEach(field => {
      if (field.fieldCode) {
        group[field.fieldCode] = field.required ? new UntypedFormControl(
          { value: field.value || '', disabled: field.readonly },
          [requiredWarningValidator()])
          : new UntypedFormControl(field.value || '');
        if (field.fieldType == 'integer' || field.fieldType == 'decimal') {
          let decimalVal = field.value;
          // decimalVal = GlobalizeService.formatNumber(decimalVal, AppConfig.USER_CULTURE);
          group[field.fieldCode] = field.required ? new UntypedFormControl({
            value: decimalVal || '', disabled: field.readonly
          }, [requiredWarningValidator(), DecimalValidator.decimalValidator]
          )
            : new UntypedFormControl(decimalVal || '', DecimalValidator.decimalValidator);
        } else if (field.fieldType == 'date') {
          let dateVal = field.value;
          if (dateVal) {
            dateVal = moment.utc(dateVal).locale(
              AppConfig.USER_CULTURE || AppConfig.BROWSER_CULTURE)
          }
          group[field.fieldCode] = field.required ? new UntypedFormControl({
            value: dateVal.isValid() ? dateVal : '', disabled: field.readonly
          }, [requiredWarningValidator()]
          )
            : new UntypedFormControl(dateVal.isValid() ? dateVal : '');
        }
        else {
          group[field.fieldCode] = field.required ? new UntypedFormControl(
            { value: field.value || '', disabled: field.readonly },
            [Validators.pattern(AppConfig.NO_WHITESPACE_REGEX), requiredWarningValidator()
            ]
          )
            : new UntypedFormControl(field.value || '');
        }
      }
    });
    return new UntypedFormGroup(group);
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}