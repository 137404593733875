import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError, lastValueFrom, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { CommonService } from './common.service';
import { ConfigurationReport } from '../models/ConfigurationReport';

@Injectable({
    providedIn: 'root'
})
export class SmartpdfApiService {

    baseUrl = AppConfig.SMARTPDF_BASE_API_URL(this.commonService.getBaswareCustomerId());
    user = AppConfig.NAME_OF_THE_USER;

    constructor(
        private commonService: CommonService,
        private http: HttpClient) {
    }

    // Error handling
    errorHandler(error: any) {
        let err = {
            errorMessage: '',
            errorCode: ''
        };
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            err.errorMessage = error.error.error;
        } else {
            err.errorCode = error.status;
            err.errorMessage = error.error.error;
        }
        console.log(err.errorMessage);
        return throwError(() => error);
    }

    getConfigurations(): Observable<ConfigurationReport[]> {
        return this.http.get<any>(this.baseUrl + AppConfig.CONFIG_REPORTS).pipe(
            catchError(this.errorHandler)
        );
    }

    approveConfiguration(configurationId: string, body: any) {
        var queryParams = { username: encodeURIComponent(this.user) };
        let url = this.baseUrl + AppConfig.APPROVE_CONFIG(configurationId);
        return this.http.put(url, body, { params: queryParams }).pipe(
            catchError(this.errorHandler)
        );
    }

    rejectConfiguration(configurationId: string, body: any) {
        var queryParams = { username: encodeURIComponent(this.user) };
        let url = this.baseUrl + AppConfig.REJECT_CONFIG(configurationId);
        return this.http.put(url, body, { params: queryParams }).pipe(
            catchError(this.errorHandler)
        );
    }

    downloadReport(configurationId: string, snapshotuuid: string) {
        let url = this.baseUrl + AppConfig.DOWNLOAD_CONFIG_REPORT(configurationId, snapshotuuid);
        return this.http.get<any>(url).pipe(
            catchError(this.errorHandler)
        );
    }
}