import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-app-layout',
  templateUrl: './main-app-layout.component.html',
  styleUrls: ['./main-app-layout.component.scss']
})
export class MainAppLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
