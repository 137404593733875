import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { CommonService } from 'src/app/app-services/common.service';
import { LocalizationService } from 'src/app/app-services/localization.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'edit-single-unit-users',
  templateUrl: './edit-single-unit-users.component.html',
  styleUrls: ['./edit-single-unit-users.component.scss']
})
export class EditSingleUnitUsersComponent extends BaseTranslationService implements OnInit {

  @Output() public cancel = new EventEmitter();
  @Output() public save = new EventEmitter();
  @Input() listOfUsers: any[];
  @Input() existingUsers: any[];

  selectedUsers: any[];
  selectedArray: any[] = [];
  searchControl = new FormControl();
  filteredUsers: Observable<any[]> = new Observable();

  @Input() savingUnitChanges: boolean = false;

  subs = new SubSink();
  flexValue: number;

  constructor(public common: CommonService, localization: LocalizationService) {
    super(localization);
    this.subs.add(this.common.flexValue.subscribe(
      value => { this.flexValue = value }
    ))
  }

  ngOnInit(): void {
    this.existingUsers.forEach(eu => {
      this.selectedArray.push(this.listOfUsers.filter(u => u.email == eu.email)[0]);
    })
    this.selectedUsers = this.selectedArray;
    this.filteredUsers = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._myFilter(value))
    );
  }


  removeUser(userToRemove: any) {
    this.selectedUsers = this.selectedUsers.filter(user => user.email !== userToRemove.email);
    this.selectedArray = this.selectedUsers;
  }

  removeUsers() {
    this.selectedUsers = [];
    this.selectedArray = [];
  }

  cancelEditingUnit(ele: any) {
    this.cancel.emit(ele);
  }

  saveChanges() {
    this.savingUnitChanges = true;
    this.save.emit({ selectedUsers: this.selectedArray });
  }


  selectionChanged(event: MatOptionSelectionChange, user: string) {
    if (event.isUserInput) {
      if (event.source.selected) {
        this.selectedArray.push(user);
      } else {
        this.removeUser(user);
      }
    }
  }

  panelOpened(panel: any) {
    if (!panel.panelOpen) {
      this.searchControl.setValue('');
    } else {
      this.selectedUsers = this.selectedArray;
    }
  }

  handleInput(event: any) {
    if (
      event.keyCode !== 38 &&
      event.keyCode !== 40 &&
      event.keyCode !== 13 &&
      event.keyCode !== 32
    ) {
      event.stopPropagation();
    }
  }

  private _myFilter(value: string): string[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.listOfUsers.filter(user =>
        user.name.toLowerCase().includes(filterValue)
      );
    }
    return [];
  }
}