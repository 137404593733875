<div mat-dialog-title fxlayout="row">
    <div>{{tr(title)}}</div>
    <div class="gt-dialog-close">
        <button mat-icon-button color="primary" aria-label="{{tr('spdf.app.tooltips.close')}}"
            matTooltip="{{tr('spdf.app.tooltips.close')}}" mat-dialog-close>
            <mat-icon fontSet="far" fontIcon="fa-times"> </mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content>
    <mat-toolbar class="gt-banner gt-banner-error mb-16" *ngIf="errorState && errorMessage">
        <mat-icon fontSet="fas" fontIcon="fa-exclamation-circle" class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden"> Error </span>
        <span class="gt-banner-content mat-body-1">
            <span class="gt-banner-messageblock">{{tr(errorMessage)}}</span>
        </span>
    </mat-toolbar>
    <mat-form-field appearance="legacy">
        <mat-label class="required">
            {{tr('spdf.app.module.smartpdf.configuration.config.id')}}
        </mat-label>
        <input matInput name="configId" [ngModel]="config.configurationId" readonly disabled>
    </mat-form-field>
    <mat-form-field appearance="legacy">
        <mat-label class="required">
            {{tr('spdf.app.module.smartpdf.configuration.comment')}}
        </mat-label>
        <textarea matInput name="comment" rows="4" [formControl]="comment" required (blur)="trimInput()"
            aria-required="true" [attr.disabled]="sending ? true : null"></textarea>
        <mat-error *ngIf="comment.errors?.['required']">
            <mat-icon>error</mat-icon>
            {{tr('spdf.app.module.bu.validation.field.required')}}
        </mat-error>
    </mat-form-field>
</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close [disabled]="sending">
        {{tr('spdf.app.module.buup.cancel')}}
    </button>
    <button mat-flat-button color="primary" aria-label="Ok" (click)="ok()"
        [disabled]="sending || disableActionButton || (comment.touched && comment.invalid)">
        <div class="gt-spinner-container" *ngIf="sending">
            <mat-progress-spinner diameter="20" mode="indeterminate"> </mat-progress-spinner>
        </div>
        <span *ngIf="!sending"> {{tr(confirmButtonText)}} </span>
    </button>
</div>