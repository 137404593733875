<div class="gt-container-xl">

  <mat-toolbar class="gt-titlebar">
    <div class="gt-titlebar-back-button">
      <button mat-icon-button attr.aria-label="{{tr('spdf.app.tooltips.return')}}" color="primary" (click)="goBack()">
        <mat-icon matTooltip="{{tr('spdf.app.tooltips.goBack')}}" attr.aria-label="{{tr('spdf.app.tooltips.goBack')}}"
          fontSet="far" fontIcon="fa-arrow-left"></mat-icon>
      </button>
    </div>
    <div class="gt-titlebar-content-wrapper">
      <div class="gt-titlebar-left-content-wrapper">
        <div class="gt-titlebar-main-title">
          <h1>{{tr('spdf.app.module.buup.business.unit.user.assignments')}}</h1>
        </div>
      </div>
    </div>
  </mat-toolbar>
  <mat-toolbar #bannerE *ngIf="showErrorBanner" class=" error-banner gt-banner gt-banner-error">
    <mat-icon fontSet="fas" fontIcon="fa-exclamation-circle" class="gt-icon"></mat-icon>
    <span class="cdk-visually-hidden"> Error</span>
    <span class="gt-banner-content mat-body-1">
      <span class="gt-banner-messageblock">{{tr("spdf.app.module.bu.internal.error")}}</span>
    </span>
    <button mat-icon-button attr.aria-label="{{tr('spdf.app.tooltips.close')}}" (click)="showErrorBanner = false"
      color="gt-color-info" class="gt-banner-close-btn">
      <mat-icon fontSet="far" fontIcon="fa-times" aria-hidden="false" class="gt-icon"
        matTooltip="{{tr('spdf.app.tooltips.close')}}">
      </mat-icon>
    </button>
  </mat-toolbar>
  <mat-expansion-panel hideToggle [expanded]="true">
    <mat-expansion-panel-header #header1 (click)="header1._toggle()">
      <mat-panel-title role="heading" aria-level="2" class="pl-48">
        {{tr('spdf.app.module.bu.businessUnits')}}
        <span class="gt-badge gt-badge-secondary">{{dataSource.data.length}}</span>
      </mat-panel-title>

      <mat-panel-description>
        <ng-container *ngIf="!updatingUnits">
          <button mat-button color="primary" class="gt-btn-main" (click)="openAddUserDialog()"
            [disabled]="disableUserModifications || isContentLoading">
            {{tr('spdf.app.module.buup.add.user')}}
          </button>
          <button mat-button color="primary" class="gt-btn-main" (click)="openRemoveUserDialog()"
            [disabled]="disableUserModifications || isContentLoading">
            {{tr('spdf.app.module.buup.remove.user')}}
          </button>
          <button mat-icon-button [matMenuTriggerFor]="editUserMenu" color="primary"
            attr.aria-label="{{tr('spdf.app.tooltips.moreActions')}}" class="gt-btn-more-actions"
            [disabled]="disableUserModifications || isContentLoading">
            <mat-icon fontSet="far" fontIcon="fa-ellipsis-v" matTooltip="{{tr('spdf.app.tooltips.moreActions')}}">
            </mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="updatingUnits">
          <div class="gt-spinner-container">
            <mat-progress-spinner diameter="20" mode="indeterminate"> </mat-progress-spinner>
          </div>
        </ng-container>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div *ngIf="!isContentLoading && dataSource.data.length==0"
      class="empty-state-custom gt-empty-state-panel-body-container" [ngClass.xs]="{'gt-centered-content':true}">
      <div class="gt-row">
        <p class="gt-lead-text">{{tr('spdf.app.module.buup.no.units')}}</p>
      </div>
    </div>

    <mat-menu #editUserMenu="matMenu">
      <button mat-menu-item (click)="openReplaceUserDialog()">
        <span>{{tr('spdf.app.module.buup.replace.users')}} </span>
      </button>
      <button mat-menu-item (click)="openClearAllUserDialog()">
        <span>{{tr('spdf.app.module.buup.clear.users')}}</span>
      </button>
    </mat-menu>

    <div [hidden]="!isContentLoading && dataSource.data.length==0">
      <div fxLayout="row wrap" fxFlexFill fxLayoutGap="24px" class="pl-24">
        <mat-form-field fxFlex="{{flexValue < 66 ? 'auto' : '100'}}" appearance="legacy"
          class="gt-form-field-without-label gt-quicksearch-input filterWidth" autocomplete="off" floatLabel="always">
          <mat-label id="gt-mat-label-1"></mat-label>
          <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter(filterValue)" autocomplete="off"
            aria-labelledby="gt-mat-label-1" placeholder="{{tr('spdf.app.module.buup.filterPlaceholder')}}">

          <button class="gt-form-field-clear" mat-icon-button matSuffix *ngIf="filterValue" (click)="clearFilterValue()"
            matTooltip="{{tr('spdf.app.tooltips.clear')}}" attr.aria-label="{{tr('spdf.app.tooltips.clear')}}">
            <mat-icon> cancel </mat-icon>
          </button>

          <button mat-icon-button matSuffix disabled class="gt-search-icon"
            attr.aria-label="{{tr('spdf.app.module.buup.searchIcon')}}">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
        <mat-divider *ngIf="flexValue<66" class="divider-filter" [vertical]="true" role="separator"></mat-divider>

        <mat-form-field fxFlex="{{flexValue < 66 ? '194px' : '100'}}" appearance="legacy" class="gt-sortby-select">
          <label id="gt-mat-label">{{tr('spdf.app.module.bu.labels.sortBy')}}</label>
          <mat-select [(ngModel)]="sortValue" aria-labelledby="gt-mat-label" (selectionChange)="applySort(sortValue)">
            <mat-option *ngFor="let item of sortByItems" [value]="item">
              {{tr(item.value)}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="gt-loader-section">
        <div class="gt-loader-shade-normal loader-section-width" *ngIf="isContentLoading">
          <mat-progress-bar mode="indeterminate" [value]="progressBarValue" [bufferValue]="progressBarBufferValue">
          </mat-progress-bar>
        </div>

        <mat-table [dataSource]="dataSource" multiTemplateDataRows class="gt-expansion-table" matSort="sort">
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox color="primary" [indeterminate]="someSelected" [checked]="allSelected"
                [disabled]="disableUnitSelection || isContentLoading" (change)="setAll($event.checked)">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="gt-text-cell">
              <ng-container>
                <mat-checkbox color="primary" [(ngModel)]="element.selected" [disabled]="disableUnitSelection"
                  (change)="unitSelectionChanged(element, $event.checked)">
                </mat-checkbox>
              </ng-container>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="businessUnit">
            <mat-header-cell *matHeaderCellDef>{{tr('spdf.app.module.bu.table.header.bu')}}</mat-header-cell>
            <mat-cell *matCellDef="let element" class="gt-text-cell">
              <ng-container>{{ element.businessUnit }}</ng-container>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="eInvoicingAddress">
            <mat-header-cell *matHeaderCellDef>{{tr('spdf.app.module.bu.table.header.eInvoicingAddress')}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="gt-text-cell">
              <ng-container>{{ element.invoiceAddress }}</ng-container>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="invoicingEmailAddress">
            <mat-header-cell *matHeaderCellDef>{{tr('spdf.app.module.bu.table.header.invoicingEmailAddress')}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="gt-text-cell">
              <ng-container>{{ element.invoiceEmail}}</ng-container>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="assignedTo">
            <mat-header-cell *matHeaderCellDef>{{tr('spdf.app.module.buup.assigned.to')}}</mat-header-cell>
            <mat-cell *matCellDef="let element" class="gt-text-cell">
              <ng-container *ngFor="let u of element.users; let isLast=last">
                {{u.name}}{{isLast?'':','}}
              </ng-container>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="action-column">
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="action-column" #editIcon>
              <button mat-icon-button (click)="editUnit($event, element)" [disabled]="disableEditUnit" color="primary"
                matTooltip="{{tr('spdf.app.tooltips.edit')}}" aria-label="fa-pencil">
                <mat-icon fontSet="fas" fontIcon="fa-pencil"></mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let element">
              <div *ngIf="expandedElement === element" fxFlex="100" class="gt-expansion-element-detail">
                <edit-single-unit-users [listOfUsers]="listOfUsers" [existingUsers]="element.users"
                  [savingUnitChanges]="savingUnitChanges" (cancel)="cancelEditingUnit($event)"
                  (save)="saveUnitChanges($event, element)">
                </edit-single-unit-users>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>

          <mat-row *matRowDef="let row; columns: columnsToDisplay;" class="gt-data-row  gt-drillin-row">
          </mat-row>

          <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="gt-expansion-row">
          </mat-row>

        </mat-table>
      </div>
    </div>
    <!-- <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[5, 10, 20]" 
          (page)="pageEvent = getPaginationData($event)"
          aria-label="Select page of units">
          </mat-paginator> -->
  </mat-expansion-panel>
</div>