import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { LocalizationService } from 'src/app/app-services/localization.service';
export interface DialogData {
  messageKey1: string,
  messageKey2: string,
  messageKey3: string
}

@Component({
  selector: 'app-unsaved-data-warning',
  templateUrl: './send-default-values.component.html',
  styleUrls: ['./send-default-values.component.scss']
})
export class SendDefaultValuesComponent extends BaseTranslationService implements OnInit {

  constructor(private dialogRef: MatDialogRef<SendDefaultValuesComponent>, localization: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    super(localization);
  }

  ngOnInit(): void {
  }

  sendDefault() {
    this.dialogRef.close({
      action: 'default'
    });
  }

  goBack() {
    this.dialogRef.close({
      action: ''
    });
  }

}
